.lab-modal-container {
    width: 883px;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 10%);
    position: relative;
    top: 10%;
    bottom: 50%;
    left: auto;
    right: auto;
    margin: auto;
    padding: 5px;
    outline: 0;
    form {
        overflow-y: auto !important;
        // min-height: 494px;
        display: block;
        max-height: 80vh;
    }
    main {
        padding: 5px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .fax-number-label {
        height: 29px;
        width: 188px;
        color: gray;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 29px;
        float: right;
    }

    header {
        height: 43px;
        width: 883px;
        border-radius: 4px 4px 0 0;
        background-color: white;

        span {
            height: 29px;
            width: 63px;
            color: gray;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 40px;
            margin-top: 5px;
            margin-left: 20px;
        }

        svg {
            height: 32px;
            width: 32px;
            float: right;
            margin: 5px 5px 0 0;
            cursor: pointer;
        }
    }

    main {
        label {
            color: gray;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 29px;
            margin: 0 0px 5px;
            display: flex;
        }
    }

    footer {
        margin: 15px 10px;
        display: flex;
        justify-content: center;
        gap: 15px;
    }

    .white-btn {
        height: 20px;
        width: 50%;
        border-radius: 4px;
        background: #303e4e !important;
        border-radius: 4px;
        border: 1px solid #303e4e !important;
        cursor: pointer;
        span {
            height: 18px;
            width: 113px;
            color: rgb(237, 237, 240);
            font-size: 15px;
            letter-spacing: 0;
            line-height: 18px;
            text-align: center;
        }
        &.hide {
            display: none;
        }
    }

    .blue-btn {
        height: 20px;
        width: 50%;
        border-radius: 4px;
        background: #5571c6 !important;
        border-radius: 4px;
        border: 1px solid #5571c6 !important;
        cursor: pointer;
        span {
            height: 18px;
            width: 113px;
            color: white;
            font-size: 15px;
            letter-spacing: 0;
            line-height: 18px;
            text-align: center;
        }
    }
}
.divider_height {
    height: 2px !important;
    width: 100%;
}
.bgColor_in_progress {
    background: #e9ecf1;
    color: #667689;
    padding: 2;
}
.bgColor_completed {
    background: #ebfff3;
    color: #15a452;
    padding: 2;
}
