.divider-information .MuiFormLabel-root {
    margin-bottom: 5px;
    margin-top: 3px;
}

.divider-information .MuiBox-root {
    margin-bottom: 5px;
}

fieldset.divider-information {
    border: 1px solid #D0D0D0 !important;
    border-radius: 4px !important;
    margin-left: 6px !important;
    margin-bottom: 16px !important;
}
