@import '../../../styles/themes/color.module.scss';
.appointment-booking-container {
    padding-top: 16px;
    padding-bottom: 12px;
    span.iconWrapper {
        margin-right: 10px;
        vertical-align: middle;
        img {
            width: 20px;
            height: 23px;
        }
    }
    p.MuiTypography-root.separator {
        font-size: 18px;
    }
    & .MuiTabs-root {
        & .MuiTab-root {
            text-align: start;
            text-transform: uppercase;
        }
    }
    // .MuiTypography-separator {
    //     font-size: 18px;
    //     padding: 0;
    // }

    .booking-tabs {
        padding: 30px 0 30px 20px;
    }
    .add-button .MuiButton-outlined {
        box-sizing: border-box;
        border: 1px solid $primaryBlue;
        border-radius: 4px;
        padding: 12px 20px;
    }
    .border-top {
        border-top: 5px solid #e9ecf1;
        height: 1px;
        width: 100%;
        margin: 16px 20px;
    }
    .border-top-inside {
        border-top: 5px solid #e9ecf1;
        height: 1px;
        width: 100%;
        margin: 8px 12px;
    }
}

/* Modal Styles */
.calendar-appointment-modal {
    main {
        padding: 20px;
        .details-wrap {
            border-radius: 4px;
            min-height: 36px;
            display: flex;
            align-items: center;
            padding: 10px 15px;
            margin-bottom: 20px;
            .bar {
                border-right: 2px solid $primaryDarkBlue;
                margin-right: 10px;
            }
            .time {
                font-size: 15px;
                line-height: 18px;
                font-weight: bold;
            }
            .field1 {
                font-size: 12px;
                padding-right: 10px;
                border-right: 1px solid #111;
                margin-right: 10px;
                line-height: 13px;
            }
            .tele-field,
            .clinic-field {
                font-size: 12px;
                line-height: 13px;
            }
            .clinic-field {
                font-weight: bold;
            }
        }
    }
}

.tag-color {
    width: 35px;
    height: 35px;
    border: 1px solid #e9ecf1;
    border-radius: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 6px;
}
.border-bottom {
    border-bottom: 1px solid #CFCFCF;
    margin-bottom: 7px;
}
.page-title {
    font-size: 22px;
    font-weight: 500;
    color: #303E4E;
}

.divider-information .MuiFormLabel-root {
    margin-bottom: 5px;
    margin-top: 3px;
}

.divider-information .MuiBox-root {
    margin-bottom: 5px;
}

fieldset.divider-information {
    border: 1px solid #CFCFCF;
    border-radius: 5px;
    margin-left: 6px;
    margin-bottom: 16px;
}
legend.heading-title{
    color: #667689;
    font-size: 0.875rem;
    font-family: Rubik;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0.01em;
    text-transform: inherit;
}
.heading1-title{
    font-family: Rubik;
    font-weight: 400;
    font-size: "18px";
    color: #303E4E !important;
}
.divider_selectBoxTaxonomy {
    height: 35px !important;
    width: 3px !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}
.heading1-title-licenses{
    font-family: Rubik;
    font-weight: 400;
    font-size: "16px";
    color: #667689 !important;
}
.MuiGrid-item {
    &.MuiGrid-grid-xs-1 {
        max-width: 7%;
        flex-basis: 7%;
    }
}
.divider_AccountTab{
    height: 20px !important;
    width: 2px !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-right: 8px !important;
    background-color: #5571C6 !important;
}
.modifier_top_state{
        border: 1px solid rgb(208, 208, 208);
        justify-content: space-between;
        background-color: rgba(192, 193, 197, 0.1);
        padding: 0 5px 5px 5px;
}