.inputStyle {
    width: 103px !important;
    height: 140px;
    font-size: 30px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 160%;
    background: #fbfcfc;
    border: 1px solid #e9ecf1;
    border-radius: 8px;
    box-sizing: border-box;
    margin-right: 16px;
    color: #303e4e;
}
