legend.heading-title{
    color: #667689;
    font-size: 0.875rem;
    font-family: Rubik;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0.01em;
    text-transform: inherit;
}
fieldset.divider {
    border: 1px solid lightgray;
    border-bottom: none;
    border-left: none;
    border-right: none;
    padding-inline-start: 0;
}
.MuiAutocomplete-root{
    width: 100% !important;
} 