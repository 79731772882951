.recall_list_schedule {
    .MuiDialog-container .MuiPaper-root {
            height: inherit !important;
            min-width: 82vw !important;
    }
}

.recall-form {
    position: relative;
    overflow-x: scroll;
    // overflow-y: auto;
    height: 100%;
    flex-direction: column;
    flex-wrap: nowrap;

    .position-relative {
        position: relative;
    }

    // .textFieldTop div textarea {
    //     margin-top: 20px !important;
    // }

    .border-bottom {
        border-bottom: 1px solid rgb(232, 20, 20);
    }

    .divider-text {
        font-weight: 400;
        font-size: 19px;
        line-height: 140%;
        color: #303e4e;
        margin-left: 8px;
    }

    .divider-information .MuiFormLabel-root {
        margin-bottom: 5px;
        margin-top: 3px;
    }

    .divider-information .MuiBox-root {
        margin-bottom: 5px;
    }

    fieldset.divider-information {
        width: 100%;
        border: 1px solid rgb(207, 207, 207);
        border-radius: 5px;
        // border-bottom: none;
        // border-left: none;
        // border-right: none;
        margin-left: 6px;
        margin-bottom: 16px;
        // padding-inline-start: 0;
    }

    .legend-class {
        margin-left: 13px;

    }

    fieldset.divider {
        width: 99%;
        border: 1px solid lightgray;
        border-bottom: none;
        border-left: none;
        border-right: none;
        margin-left: 2px;
        padding-inline-start: 0;
    }

    .divider_selectBox {
        height: 21px !important;
        width: 3px !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }

    .icon_position {
        transform: translateY(15px);
    }

    .divider_height {
        height: 25px !important;
    }

    .delete_portion {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .border_right {
        border-right: 1px solid rgba(102, 118, 137, 1);

    }

    .text_style {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 5px;
        margin-top: 3px;
        color: #516983;
        line-height: 1.5rem;
        letter-spacing: 0.02em;
        text-transform: capitalize;
        font-family: Rubik;
        padding: 0;
    }

    .delete_icon {
        margin-left: 9px;
        margin-top: 8px;
    }


    .mc-container {
        margin-left: 0;
        margin-right: 0;

        .mc-headers {
            font-weight: 500;
        }

        .row:not(:last-child) {
            margin-bottom: 12px;
        }

        .MuiGrid-item {
            &.MuiGrid-grid-xs-1 {
                max-width: 10%;
                flex-basis: 10%;
            }

            // flex: 1;
            // max-width: inherit;

            .MuiFormControl-root:not(:first-child),
            .MuiBox-root:not(:first-child) {
                margin-top: 8px;
            }
        }

        .action {
            width: 60px;
            text-align: center;
        }

        .mc-delete-btn {
            padding: 9px;
            background: #ffffff;
            border: 1px solid #d9dde3;
            border-radius: 800px;
        }
    }

    .action-btns {
        margin-top: 4rem;
        padding: 0 8px;
        margin-bottom: 2rem;

        .MuiButton-root {
            padding: 8px 64px;
            gap: 10px;
            background-color: #303e4e;
            border-radius: 800px;
            color: #ffffff;
            font-weight: 400;
            font-size: 15px;
            line-height: 140%;

            span.MuiButton-label {
                font-weight: 400;
                font-size: 15px;
                line-height: 140%;
            }

            &:not(:first-child) {
                margin-left: 16px;
            }

            &:nth-child(2) {
                background-color: #5571c6;
            }

            &:nth-child(3) {
                background-color: #4c8080;
            }
        }
    }

    .border-bottom {
        border-bottom: 1px solid rgb(207, 207, 207) !important;
    }

    .page-title {
        font-size: 22px;
        font-weight: 500;
        color: #303e4e;
        margin: 5px 0 0 4px;
        padding: 0;
        padding-left: 8px;
        margin-bottom: 10px;
    }

    .date-text {
        margin-left: 10px;
    }

    .link-back {
        color: #5571c6;
        margin-left: 8px;
        text-decoration: none;
        font-size: 0.875rem;
        font-weight: 400;
        cursor: pointer;
        max-height: 32px;
    }

    .page-RecordCall {
        margin-bottom: 10px !important;
        margin-top: 6px !important;
    }

    .recall-form .border-bottom {
        margin-bottom: 0px !important;
    }

    .position-button {
        position: absolute !important;
        bottom: 3px !important;
        right: 14px !important;
    }

    .cancel-button {
        margin-top: 16px !important;
    }

    .date-text {
        margin-bottom: 22px !important;
    }
}