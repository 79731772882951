@import '../../styles/themes/color.module.scss';
@import '../../styles/themes/sizing.scss';
.header-container {
    .navTextContainer {
        display: flex;
        background-color: $success;
        padding: 0 80px;
        p {
            font-size: 16px;
            font-weight: 500;
            color: white;
            padding: $space-10;
            margin: 0;
            span {
                text-decoration: underline;
                margin-left: 12px;
            }
        }
    }
    .navbar {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;
    }
    .ulContainer {
        display: inline-flex;
        align-items: center;
        margin: 0;
        padding: 0;
        gap: 40px;
        li {
            list-style: none;
            padding: 20px;
            border-radius: 4px;
            a {
                text-decoration: none;
                color: black;
                font-weight: 500;
                font-size: 16px;
                &:hover {
                    color: $secondaryBlue;
                }
            }
            .btn-primary {
                font-weight: 500;
                width: 100%;
            }
        }
    }
    .sx-dropi {
        width: 100%;
        z-index: 1;

        ul {
            padding-left: 10px;
        }
        li {
            width: 100%;
            height: 30px;
            padding: 0;
        }
    }
    .navbar {
        .inner-hamburger-menu {
            width: 40px;
            position: relative;
            border: 0px;
            z-index: 1;
            cursor: pointer;
            background-color: transparent;
        }
    }
}

.inner-hamburger-menu {
    position: fixed;
    left: 20px;
    top: 14px;
    border: 0px;
    width: 40px;
    z-index: 1;
    cursor: pointer;
    background-color: white;
}

.mobile-menu-wrapper {
    button.hamburger-menu {
        border: 0px;
        cursor: pointer;
        background-color: white;
    }
    button.closeIcon-btn {
        background: none;
        border: none;
    }
    svg.MuiSvgIcon-root {
        color: $primaryGray;
        font-size: 30px;
        padding: 20px 15px;
    }
    .mobile-menu {
        .logo-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            img.mobile-logo {
                max-width: 115px;
                margin-left: 20px;
            }
        }
        .app__sidebar {
            box-shadow: none;
        }
        ul {
            margin: 0;
            padding: 0;
            li {
                display: inline-flex;
                align-items: center;
                height: 56px;
                width: 100%;
                padding: 0;
                justify-content: flex-start;
                flex-direction: row;
                &.active,
                &:active,
                &:hover,
                &:focus {
                    background: #edf0f4;
                }
                // &:first-child{
                //     margin-top:24px;
                // }
                a {
                    padding: 15px 20px;
                    text-decoration: none;
                    font-size: 18px;
                    display: block;
                    color: $primaryGray;
                    cursor: pointer;
                    &.active,
                    &:active,
                    &:hover,
                    &:focus {
                        color: #1b2955;
                        font-weight: bold;
                    }
                }
                button {
                    width: auto;
                    margin: 15px 20px;
                }
            }
        }
    }
}

.logo-wrapper {
    .flex-end {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }
}

@media only screen and (max-width: 1280px) {
    .header-container .ulContainer {
        gap: 20px;
    }
}
@media only screen and (max-width: 1024px) {
    .header-container {
        .navbar {
            height: 60px;
            justify-content: flex-start;
            padding: 10px 20px;
            &.navwrapper {
                justify-content: center;
            }
            .logo-res-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
            }
        }
        .navTextContainer {
            display: none;
        }
    }
}
@media only screen and (max-width: 768px) {
    .header-container {
        // .navTextContainer {
        //     padding: 0 20px;
        // }
        .app_header-logo {
            width: 100px;
            padding: 10px;
            margin-left: 51px !important;
        }
        img {
            width: 100px;
        }
    }
}

@media only screen and (max-width: 582px) {
    // .header-container {
    //     .navTextContainer {
    //         padding: 0 10px;
    //     }
    // }
    .mobile-menu-wrapper {
        svg.MuiSvgIcon-root {
            padding: 15px 10px;
        }
    }
    body {
        background: $success;
    }
    .background-container,
    .mobile-menu-wrapper button.hamburger-menu,
    .mobile-menu-wrapper svg.MuiSvgIcon-root {
        color: $primaryWhite;
        background: $success;
    }
    .mobile-menu-wrapper button.closeIcon-btn svg {
        color: $primaryDarkGray;
        background: none;
    }
    .container.register-wrapper,
    .container.login-wrapper {
        background: $success;
    }
}
