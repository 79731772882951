@import '../../../styles/themes/color.module.scss';

.text-hightlight {
    font-size: 14px;
    font-family: Rubik;
    color: #f90000;
}

.hightlight {
    font-size: 14px;
    font-family: Rubik;
    color: #667689;
}

#visit-type-settings {
    width: 100%;
    .modal-spacer {
        margin: 12px 0;
        width: 100%;
    }
}

.helper-text {
    color: #f44336 !important;
    margin-left: 1.6rem;
    margin-bottom: 1rem;
    font-size: 11px;
    text-align: left;
    font-family: Rubik;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.4px;
    text-transform: none !important;

    &.margin-0 {
        margin-left: 0;
        margin-top: 0.5rem;
    }
}
