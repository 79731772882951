.containerProvider {
    max-width: 1280px;
    padding: 0;
    margin: 0 auto;
    overflow: hidden;

    .inner-wrapper-logo-mobile {
        display: none;
        @media (max-width: 582px) {
            display: block;
        }

        .mobile-logo-header {
            justify-content: center;
            align-items: center;
            display: flex;
            margin: 30px auto;
        }
    }
    @media (max-width: 582px) {
        background: white;
    }

    .form-wrapper-provider {
        height: 100vh;
        flex-wrap: inherit;
        @media (max-width: 582px) {
            justify-content: center;
        }

        .inner-wrapper-logo {
            position: absolute;
            top: 60px;
            left: 60px;
            display: block;
        }

        .form-video-provider {
            position: relative;
            max-width: 70%;
            flex-basis: 70%;
            @media (max-width: 582px) {
                display: none;
            }
        }

        .formik-content {
            position: relative;
            margin: 80px 0 0px;
            height: calc(100vh - 80px);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .form-login-provider {
            max-width: 30%;
            flex-basis: 30%;
            padding: 0 60px;
            min-width: 450px;
            height: 100vh;
            overflow-y: auto;
            @media (max-width: 582px) {
                max-width: 100%;
                min-width: 100%;
                padding: 0 20px;
            }

            .main-header {
                display: flex;
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 500;
                font-size: 32px;
                line-height: 140%;
                color: #303e4e;
            }

            .main-description {
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 140%;
                color: #667689;
            }

            .input-label {
                margin-top: 16px;
            }

            .input-label-username {
                margin-top: 60px;
            }

            .forgot-password-radio {
                margin-top: 24px;
                justify-content: space-between;
            }

            .remember-password-label {
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 140%;
                color: #303e4e;
                margin-right: 0;
            }

            #forgot-password-btn {
                display: flex;
                align-items: center;
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 140%;
                color: #303e4e;
            }

            .center-align {
                justify-content: center;
                align-items: center;
                margin-bottom: 164px;
                @media (min-width: 1440px) {
                    margin-top: 50px;
                    margin-bottom: 50px;
                }
                @media (max-width: 582px) {
                    position: unset;
                }

                .btn-primary {
                    width: 100%;
                }

                h5 {
                    justify-content: center;
                    display: flex;
                    font-family: 'Rubik';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 140%;
                    color: #303e4e;
                    @media (max-width: 582px) {
                        font-size: 14px;
                    }
                }

                .sign-up-now-link {
                    margin-left: 5px;
                    color: #5571c6;
                }
            }

            .remember-send-otp-label {
                margin-left: 0;
                margin-top: 24px;
            }

            .remember-send-otp-label span {
                margin-left: 10px;
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 140%;
                color: #5571c6;
            }

            .input-label-Authentication-code {
                margin-bottom: 8px;
            }

            .label--back {
                justify-content: center;
                margin-left: 0;
            }

            .label--back span {
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 140%;
                display: flex;
                align-items: center;
                color: #5571c6;
                margin-left: 10px;
            }

            .align-item-center-wrong-code {
                margin: 5px 0;

                .main-wrong-code {
                    font-family: 'Rubik';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 140%;
                    display: flex;
                    align-items: center;
                    color: #e81212;
                }
            }

            .main-successful {
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 140%;
                display: flex;
                align-items: center;
                color: #14ae5c;
            }
        }
    }

    .action--play {
        z-index: 9999;
        position: absolute;
        bottom: 100px;
        left: 0;
    }
}
