@import '../../../styles/themes/color.module.scss';
.appointment-booking-container {
    padding-top: 16px;
    padding-bottom: 12px;
    span.iconWrapper {
        margin-right: 10px;
        vertical-align: middle;
        img {
            width: 20px;
            height: 23px;
        }
    }
    .heading1-title {
        font-size: 16px;
        color: $primaryBlue;
        border-radius: 4px;
    }
    p.MuiTypography-root.separator {
        font-size: 18px;
    }
    & .MuiTabs-root {
        & .MuiTab-root {
            text-align: start;
            text-transform: uppercase;
        }
    }
    // .MuiTypography-separator {
    //     font-size: 18px;
    //     padding: 0;
    // }

    .booking-tabs {
        padding: 30px 0 30px 20px;
    }
    .add-button .MuiButton-outlined {
        box-sizing: border-box;
        border: 1px solid $primaryBlue;
        border-radius: 4px;
        padding: 12px 20px;
    }
    .border-top {
        border-top: 5px solid #e9ecf1;
        height: 1px;
        width: 100%;
        margin: 16px 20px;
    }
    .border-top-inside {
        border-top: 5px solid #e9ecf1;
        height: 1px;
        width: 100%;
        margin: 8px 12px;
    }
}

/* Modal Styles */
.calendar-appointment-modal {
    main {
        padding: 20px;
        .details-wrap {
            border-radius: 4px;
            min-height: 36px;
            display: flex;
            align-items: center;
            padding: 10px 15px;
            margin-bottom: 20px;
            .bar {
                border-right: 2px solid $primaryDarkBlue;
                margin-right: 10px;
            }
            .time {
                font-size: 15px;
                line-height: 18px;
                font-weight: bold;
            }
            .field1 {
                font-size: 12px;
                padding-right: 10px;
                border-right: 1px solid #111;
                margin-right: 10px;
                line-height: 13px;
            }
            .tele-field,
            .clinic-field {
                font-size: 12px;
                line-height: 13px;
            }
            .clinic-field {
                font-weight: bold;
            }
        }
    }
}
