.wrapper{
    width: 270px;
  }
.select-btn, li{
    display: flex;
    justify-content: space-between;
    // align-items: center;
    cursor: pointer;
  }
  .select-btn{
    height: 30px;
    // padding: 0 50px;
    padding: 0 10px;
    font-size: 16px;
    background: #fff;
    border-radius: 4px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    opacity: 0.777;
  }
  .select-btn i{
    font-size: 31px;
    transition: transform 0.3s linear;
  }
  .select_card_holder_customize {
    width: 245px;
    max-width: 245px;
    padding: 10px;
    background: #fff;
    border: 1px solid rgb(211, 211, 211);
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px #00000040;
    .search_holder_customize {
        cursor: pointer;
        .MuiFilledInput-underline::before,
        .MuiFilledInput-underline::before {
            content: none;
        }

        .MuiInputLabel-filled {
            margin: 0;
            font-family: Rubik;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0em;
            text-align: left;
        }

        .MuiFilledInput-input {
            padding: 12px 8px;
            border-radius: 5px;
        }

        .MuiIconButton-edgeEnd {
            margin-right: 0;
        }
        .MuiFormControl-root {
            width: 100% !important;
        }
    }
    .scrollable_content_customize {
        max-height: 150px;
        max-width: 250px;
        overflow: auto;
        flex-direction: row;

        .MuiFormControlLabel-root {
            width: 100%;
        }
    }
    .review_provider_list_{
        font-family: Rubik;
        font-size: 16px;
        font-weight: 400;
        padding-top: 9px;
        padding-bottom: 9px;
        padding-left: 5px;
        cursor: pointer;
    }
    .review_provider_list_:hover{
        background:#F1F5FD;
    }
}