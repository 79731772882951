.capitalize {
    text-transform: capitalize;
}

.scrollbar_small
{
	
	float: left;
	height: auto;
	width: 100%;
	
	overflow-y: scroll;
	margin-bottom: 25px;
}

.force-overflow-y
{
	min-height: 300px;
}

#wrapper
{
	text-align: center;
	width: 500px;
	margin: auto;
}

/*
 *  STYLE 1
 */

#style-1::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px #F5F5F5;
	border-radius: 10px;
	background-color: #F5F5F5;
}

#style-1::-webkit-scrollbar
{
	width: 5px;
	background-color: #F5F5F5;
}

#style-1::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px #F5F5F5;
	background-color: #F5F5F5;
}
