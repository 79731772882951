@import './styles/themes/lato.scss';
@import './styles/themes/color.module.scss';
@import './styles/themes/sizing.scss';
@import './styles/themes/utilities.scss';

body {
    margin: 0;
    padding: 0;
    font-family: 'Rubik', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

code {
    font-family: 'Rubik', sans-serif;
}

a {
    text-decoration: none;
}

.swal2-styled {
    height: 40px;
    min-width: 150px;
    font-weight: 500;
    color: $primaryWhite !important;
    background-color: $primaryDarkBlue !important;
    text-transform: capitalize !important;
}

// #st-br::-webkit-scrollbar {
//     width: 0px;
// }
// #st-br::-webkit-scrollbar-track {
//     background: rgb(179, 177, 177);
//     border-radius: 10px;
// }
// #st-br::-webkit-scrollbar-thumb {
//     background: rgb(136, 136, 136);
//     border-radius: 10px;
// }
// #st-br::-webkit-scrollbar-thumb:hover {
//     background: rgb(100, 100, 100);
//     border-radius: 10px;
// }
// #st-br::-webkit-scrollbar-thumb:active {
//     background: rgb(68, 68, 68);
//     border-radius: 10px;
// }
.form-disabled {
    pointer-events: none;
    opacity: 0.7;
}

.content-align-center {
    text-align: center;
    justify-content: center;
}

.content-align-left {
    text-align: left;
}

.appt-table .MuiGrid-root.content-align-center.MuiGrid-container {
    justify-content: flex-start;
}

/*** Generic Styles ***/

.app-layout-container-component-area {
    padding: 20px;

    h2.heading {
        font-size: 30px;
        line-height: 45px;
        margin-bottom: 30px;
        color: $primaryDarkBlue;
        letter-spacing: 0;
    }

    .component-wrapper {
        padding: 30px;
        background-color: $primaryWhite;
        border-radius: 4px;
        box-shadow: 0 0 13px 0 rgb(222 231 241 / 50%);
    }
}

// button.MuiButton-contained.top0 {
//     width: 100%;
// }

/** Select Styles **/

svg.MuiSvgIcon-root.MuiSelect-icon.MuiSelect-iconOutlined {
    color: $primaryGray;
    // background: $primaryWhite;
}

.MuiList-padding {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.MuiPaper-root .MuiSelect-select:focus {
    background: transparent;
}

.MuiPaper-root .MuiListItem-root.Mui-selected,
.MuiPaper-root .MuiListItem-root.Mui-selected:hover {
    background-color: $lightestGray;
}

.MuiPaper-rounded {
    border-radius: 4px;
}

.MuiListItem-button:hover {
    background-color: $lightestGray !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
    .MuiIconButton-root {
        color: $primaryDarkGray;
    }
}

.MuiFormControl-root.MuiTextField-root.Search-bar.MuiFormControl-fullWidth .MuiInputBase-input {
    font-size: 14px !important;
}

.MuiPaper-root.MuiPaper-elevation1.MuiPaper-rounded input {
    font-size: 14px;
}

/*** Typography Styles ***/
.MuiTypography-root {
    &.MuiTypography-colorPrimary {
        transition: color 0.2s ease-in-out 0s;
        color: $primaryBlue;

        &:hover {
            color: $secondaryBlue;
        }
    }

    p.MuiTypography-root.MuiTypography-body2 {
        &.MuiTypography-colorPrimary {
            transition: color 0.2s ease-in-out 0s;
            color: $primaryBlue;

            &:hover {
                color: $secondaryBlue;
            }
        }
    }
}

.abs-icon {
    position: absolute;
    right: 10px;
    top: 10px;
}

/** Fix for table double border **/
.MuiDataGrid-table-card-wrapper {
    .MuiDataGrid-root {
        border: none;
    }

    .MuiDataGrid-root .MuiDataGrid-columnHeader,
    .MuiDataGrid-root .MuiDataGrid-cell {
        border-right: none;
    }
}

/** Fix for Placeholder - search req.by **/
.MuiFormControl-root.MuiTextField-root.Search-bar.MuiFormControl-fullWidth .MuiInputBase-input {
    font-size: 14px;
}

.break-textspace {
    white-space: break-spaces;
}

.cursor-pointer {
    cursor: pointer;
}

.bold {
    font-weight: 700;
}

.MuiOutlinedInput-root {
    border-radius: 6px;
    height: fit-content;
    background: #fbfcfc !important;
}

.MuiOutlinedInput-notchedOutline {
    border: 1px solid #e9ecf1 ;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border: 1px solid $primaryDarkGray ;
}

.MuiInputBase-input.MuiAutocomplete-input {
    color: $primaryDarkGray ;
    font-size: 14px !important;
}

.MuiInputBase-root:focus-within {
    box-shadow: none !important;
}

.MuiInputBase-root:focus {
    box-shadow: none !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-width: 1px ;
    border-color: #889db6 ;
}

fieldset {
    border-color: #889db6 ;
    border-width: 1px !important;
}

.bg-white {
    background: #fff;
}

.MuiDataGrid-columnHeaderTitleContainerContent {
    width: 100%;
}

@media only screen and (max-width: 1440px) {
    .MuiCardHeader-content .MuiCardHeader-title {
        font-size: 16px;
        line-height: 24px;
    }

    .MuiDataGrid-root {
        .MuiDataGrid-columnHeaderTitle p {
            font-size: 14px;
        }

        .MuiDataGrid-columnHeaderTitleContainer p {
            font-size: 14px;
        }
    }

    .MuiCardContent-root {

        .MuiDataGrid-columnHeader,
        .MuiDataGrid-cell {
            padding: 0 4px !important;
        }
    }

    .MuiCardHeader-action p {
        font-size: 14px;
    }

    .MuiFormControl-root.MuiTextField-root.Search-bar.MuiFormControl-fullWidth .MuiInputBase-input {
        font-size: 14px !important;
    }

    .MuiPaper-root.MuiPaper-elevation1.MuiPaper-rounded input {
        font-size: 14px;
    }
}

@media only screen and (max-width: 768px) {
    .app-layout-container-component-area {
        h2.heading {
            font-size: 18px;
            line-height: 24px;
        }
    }
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
    display: none;
}

.required {
    &:after {
        content: '*';
        color: red;
    }
}

.error {
    color: red;
    margin-bottom: 1rem;
    margin-left: 5px;
}

.d-flex {
    display: flex;

    &.column {
        flex-direction: column;
    }

    &.justify-space-between {
        justify-content: space-between;
    }

    &.justify-space-around {
        justify-content: space-around;
    }

    &.justify-center {
        justify-content: center;
    }

    &.align-center {
        align-items: center;
    }

    &.align-flex-start {
        align-items: flex-start;
    }

    &.align-flex-end {
        align-items: flex-end;
    }

    &.justify-flex-end {
        justify-content: flex-end;
    }

    &.justify-flex-start {
        justify-content: flex-start;
    }
}

.title-header-table {
    .MuiDataGrid-columnHeaderTitle {
        font-weight: bold;
        color: red;
    }
}

// .MuiFormLabel-root:has(+ .MuiBox-root, + .MuiFormControl-root, + div) {
//     margin: 0 0 5px 0;
// }

.SnackbarContent-root.SnackbarItem-contentRoot.bg-5571C6 {
    background-color: #5571c6;
}

.hide-scrollbar {
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

.MuiFormHelperText-root.Mui-error {
    text-transform: initial;
}

.MuiPagination-ul {
    grid-gap: 8px;
}

#cstm-simple-popover {
    .MuiPopover-paper {
        box-shadow: 0px 8px 16px 0px #00000024;
    }
}

.popover_container {
    background: #fff;
    color: #172b4d;
    padding: 10px 20px;

    .heading_holder {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .heading {
            font-family: 'Rubik';
            font-size: 20px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0px;
            text-align: left;
        }
    }

    .content {
        font-family: 'Rubik';
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: left;
        color: #172b4d;
        padding-bottom: 15px;
    }

    .btn_holder {
        display: flex;
        flex-wrap: wrap;
        justify-content: end;
        align-items: center;

        .no_bg {
            background: transparent;
            color: #42526e;
            margin: 5px;
        }

        .error {
            background: #cf2020;
            color: #fff;
            margin: 5px;
        }
    }
}

.popover_card_holder {
    max-width: 300px;
    max-height: calc(100vh - 180px);
    overflow: auto;
    padding: 20px;
    background: #fff;
    border: 1px solid #5571c6;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px #00000040;

    .heading_holder {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #d0d0d0;
        padding-bottom: 10px;
        margin-bottom: 10px;

        .heading {
            margin: 0;
            font-family: Rubik;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            color: #000;
        }

        .clear_holder {
            font-family: Rubik;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0em;
            color: #5571c6;
            margin: 0;
            cursor: pointer;
        }
    }

    .cstm_accordion_holder {
        .MuiTypography-body1 {
            font-family: Rubik;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0em;
            text-align: left;
            color: #303e4e;
        }

        .summary_holder {
            min-height: auto;
            padding: 0;
            margin: 10px 0;
            font-weight: 600;

            .MuiAccordionSummary-content {
                margin: 0;
                align-items: center;

                .MuiChip-deletable {
                    background: #5571c6;
                    margin-left: 10px;
                    padding: 2px 10px;
                    border-radius: 20px;

                    .MuiChip-label {
                        font-family: Rubik;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 20px;
                        letter-spacing: 0em;
                        padding-right: 10px;
                        color: #fff;
                    }

                    svg {
                        fill: #fff;
                        margin: 0;
                    }
                }
            }
        }

        .MuiAccordionSummary-expandIcon {
            transform: rotate(-90deg);

            svg {
                fill: #667689;
                margin-right: 5px;
            }
        }

        .MuiAccordionSummary-expandIcon.Mui-expanded {
            transform: rotate(0deg);
        }

        .details_holder {
            padding: 10px;
            border: 1px solid #c0c1c5;
            border-radius: 5px;

            .search_holder {

                .MuiFilledInput-underline::before,
                .MuiFilledInput-underline::before {
                    content: none;
                }

                .MuiInputLabel-filled {
                    margin: 0;
                    font-family: Rubik;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 22px;
                    letter-spacing: 0em;
                    text-align: left;
                }

                .MuiFilledInput-input {
                    padding: 12px 8px;
                    border-radius: 5px;
                }

                .MuiIconButton-edgeEnd {
                    margin-right: 0;
                }
            }

            .scrollable_content {
                max-height: 150px;
                max-width: 250px;
                overflow: auto;
                flex-direction: row;

                .MuiFormControlLabel-root {
                    width: 100%;
                }
            }

            .MuiCheckbox-colorSecondary.Mui-checked:hover {
                background: rgba(85, 113, 198, 0.04);
            }

            .MuiCheckbox-colorSecondary.Mui-checked {
                color: #5571c6;
            }
        }
    }
}

.history-list-note-field {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 99%;
    margin-bottom: 2px;
    cursor: pointer;
    // margin-left: 3px;
    padding: 4px 0;
}

.history-list-note-field:hover {
    background-color: #ccd9f9 !important;
    cursor: pointer;
}

.active {
    background-color: #303e4e !important;
}

.history-list-note-alert-text {
    width: 100%;
    cursor: pointer;
    background-color: #fff;
    display: flex;
    justify-content: center;
    margin-top: 2px;
}

.history-list-note-alert-text-container {
    display: flex;
    cursor: pointer;
    padding: 8px 0;
    margin: 5px;
    width: 100%;
}

.history-list-note-alert-text-container:hover {
    background-color: #ccd9f9 !important;
}

.history-note-save-button {
    display: flex !important;
    height: 32px !important;
    padding: 6px 12px !important;
    align-items: center !important;
    border-radius: 5px !important;
    background-color: #c0c1c5;
    font-size: 14px !important;
    font-weight: 500 !important;
    cursor: pointer !important;
}

.history-note-close-button {
    display: flex !important;
    height: 32px !important;
    padding: 6px 12px !important;
    align-items: center !important;
    border-radius: 5px !important;
    background-color: #fff !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #989ca8 !important;
    cursor: pointer !important;
}

.history-note-close-view-button {
    display: flex !important;
    height: 32px !important;
    padding: 6px 12px !important;
    align-items: center !important;
    border-radius: 5px !important;
    background-color: #5571c6 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #fff !important;
    cursor: pointer !important;
}

/* Style for the "View All" button */
.view-all-wrapper {
    width: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.view-all-button {
    background-color: transparent !important;
    /* Button background color */
    color: #007bff !important;
    /* Text color */
    border: none !important;
    /* Remove the border */
    // padding: 6px 12px !important; /* Add some padding to the button */
    font-size: 13px !important;
    /* Font size */
    cursor: pointer !important;
    /* Add a pointer cursor on hover */
    text-decoration: underline !important;
    /* Underline the text */
    display: inline-block !important;
    /* Display as inline-block to make the button size fit the content */
    height: auto;
}

.staff-notes-container {
    width: 468px;
    height: auto;
    border-radius: 5px !important;
    background-color: #ffffff !important;
    padding: 5px !important;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.24), 0px 8px 16px 0px rgba(0, 0, 0, 0.28);
}

.staff-notes-text-container {
    width: 100% !important;
}

.staff-notes-button-container {
    display: flex !important;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    flex-wrap: wrap;
    margin-top: 1px;
}

.staff-notes-button-wraper {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    padding: 2px 8px;
    width: 100%;

    .MuiCheckbox-colorSecondary.Mui-checked {
        color: #5571c6;
    }
}

.staff-notes-alert-note-container {
    width: 100% !important;
}

.divider-between-button-alert-note {
    width: 100% !important;
    background-color: #fff;
    display: flex;
    justify-content: center;
    margin: 2px 0px 0px 0px;
}

.history-note-list-container {
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    max-height: 115px;
    // overflow-y: auto;
    justify-content: center;
    align-items: center;
    padding-bottom: 5px;
}

.history-note-list {
    width: 98%;
    background-color: #fff;
    height: 100%;
    overflow-y: scroll;
}

.staff-note-text-area-button-container {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
}

.staff-note-alert-container {
    display: flex;
    justify-content: flex-end;
}

.staff-note-warning-text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
}

.alert-textfield-top div textarea {
    margin-top: 20px !important;
}

@media only screen and (max-width: 1920px) {
    .categoryNotesEqModelCollapse {
        width: 1512px !important;
    }

    .categoryNotesLSCollapse {
        width: 1640px !important;
    }

    .categoryNotesEqModelLSCollapse {
        width: 1340px !important;
    }

    .categoryNotesEqModelExpand {
        width: 1740px !important;
    }
}

@media only screen and (max-width: 1440px) {
    .categoryNotesEqModelCollapse {
        width: 1032px !important;
    }

    .categoryNotesLSCollapse {
        width: 1160px !important;
    }

    .categoryNotesEqModelLSCollapse {
        width: 840px !important;
    }

    .categoryNotesEqModelExpand {
        width: 1334px !important;
    }
}

@media only screen and (max-width: 1366px) {
    .categoryNotesEqModelCollapse {
        width: 885px !important;
    }

    .categoryNotesLSCollapse {
        width: 1030px !important;
    }

    .categoryNotesEqModelLSCollapse {
        width: 710px !important;
    }

    .categoryNotesEqModelExpand {
        width: 1200px !important;
    }
}


// Here goes the utility classes

.pb-4 {
    padding-bottom: 1rem;
}

.p-4 {
    padding: 1rem;
}

.p-5 {
    padding: 1.25rem;
}

.h-full {
    height: 100%;
}

.parent_tabs_holder {
    .MuiTabs-scroller {
        flex: auto;
        width: calc(100vw - 260px);
    }
}