.modifier_top {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    border: 1px solid rgb(208, 208, 208);
    margin-bottom: 9px;
    background-color: rgba(192, 193, 197, 0.1);
    padding: 0 5px 5px 5px;
}
.delete_icon {
    margin-left: 9px;
    margin-top: 8px;
}
.divider_height {
    height: 25px !important;
}
.icon_position {
    transform: translateY(15px);
}
