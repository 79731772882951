.inpatient-psychiatric-history-form {
    .MuiGrid-item > p,
    .MuiFormControl-fullWidth > p {
        margin-bottom: 2px;
    }
    .MuiFormHelperText-root {
        text-transform: none;
    }

    .MuiGrid-spacing-xs-3 > .MuiGrid-item:not(:last-child) {
        padding-bottom: 0;
    }

    #demo-radio-buttons-group-label span {
        font-size: 14px;
        font-weight: 500;
    }
}
