.app_header-mobile {
    background: #e6e6e6;
    .wrap-header-ct {
        display: flex;
        justify-content: space-between;
        padding: 5px 30px;
        align-items: center;
        background: #fff;
        .icon-menu-mb {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            border-radius: 5px;
            background: #f0f0f0;
            svg {
                width: 20px;
                height: 20px;
                color: #404040;
            }
        }
        .logo-wrap-siderbar {
            margin-left: 15px;
            img {
                height: 38px;
                width: auto;
            }
        }
        .iconNotification {
            .MuiButtonBase-root {
                width: 30px;
                height: 30px;
                border-radius: 5px;
                background: #f0f0f0;
            }
        }
    }
    .title-page {
        padding: 15px 30px;
        background: #fff;
        margin-top: 6px;
        border-bottom: solid 1px #f0f0f0;
        .text-title {
            font-size: 20px;
            color: #333;
        }
    }
}
