@import '../../../styles/themes/color.module.scss';
.btn-log-out {
    border-top: 1px solid #e9ecf1;
    padding-top: 16px;
    height: 24px !important;
}
.text-btn-log-out {
    height: 24px !important;
}

.is__show {
    width: 78px !important;
    min-width: 78px !important;
}

.btn__option__left__menu {
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 56px;
}

.app__sidebar-mobile {
    transition: all 0.2s;
    height: 100%;
    width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    background-color: $bgGray;
    max-width: 250px;
    min-width: 250px;
    z-index: 9999999999999999;
    transform: translateX(-300px);
    &.show-menu-mobile {
        transform: translateX(0);
    }
    // box-shadow: 4px 0px 13px 0 rgb(222 231 241 / 50%);
    &__menu-item {
        height: 46px;
        margin: 0 16px;
        position: relative;
        width: 100%;
        &:first-child {
            margin-top: 24px;
        }
        &:last-child {
            margin-bottom: 24px;
        }
        &-active {
            background-color: $primaryBlue;
            border-radius: 8px;
        }
        &-child-active {
            background-color: unset !important;
            border-radius: 8px;
        }

        .nav-wrap {
            width: -webkit-fill-available;
            display: inline-flex;
            align-items: center;
            height: 46px;
            padding: 0 15px;
            justify-content: flex-start;
            flex-direction: row;
            position: relative;

            img {
                width: 20px;
                height: 20px;
            }
            .nav-child {
                margin-left: 6px;
                width: 8px;
                height: 8px;
            }
            .menu {
                padding: 12px 16px;
                text-decoration: none;
                font-size: 16px;
                display: block;
                color: $primaryGray;
                cursor: pointer;
                width: -webkit-fill-available;
            }
            .menu-child {
                font-size: 14px;
                padding: 12px 10px;
            }
            &.notification span {
                position: relative;
                &.bagde {
                    background: $primaryGray;
                    font-size: 10px;
                    line-height: 12px;
                    height: 18px;
                    width: 18px;
                    position: absolute;
                    right: 0;
                    top: 6px;
                    border-radius: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: $primaryWhite;
                    font-weight: bold;
                }
            }
            .active-menu {
                color: $primaryWhite;
                // font-weight: bold;
                // width: -webkit-fill-available;
            }
            .active-menu-child {
                color: $primaryBlue;
            }
        }
    }
}

.app__sidebar-mobile {
    * {
        box-sizing: border-box;
    }
    .logo-wrap-siderbar {
        width: 100%;
        text-align: center;
        height: 122px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 1100px) {
            height: 90px;
        }
        img {
            height: 50px;
            @media (max-width: 1100px) {
                height: 45px;
            }
        }
    }
    .wrap-menu-center {
        height: calc(100vh - 90px - 70px);
        overflow-y: auto;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    &.is__show {
        .wrap-menu-center {
            overflow: unset;
        }
        .wrap-navlink {
            .wrap-navlink-lv1 {
                &:hover {
                    .nav-link-lv1 {
                        background-color: #d7e1ff;
                    }
                }
                .nav-link-lv1 {
                    padding-left: 15px;
                    // justify-content: center;
                    .icon-down {
                        opacity: 0;
                    }
                    .text-link {
                        opacity: 0;
                    }
                }
            }
        }
    }
    .wrap-navlink {
        &.bot {
            margin-top: 60px;
            margin-bottom: 20px;
        }
        padding: 0 14px;
        .wrap-navlink-lv1 {
            &:hover {
                .wrap-navlink-2-tootip {
                    opacity: 1;
                    visibility: visible;
                    pointer-events: unset;
                }
            }
            .nav-link-lv1 {
                width: 100%;
                height: 46px;
                padding: 0 15px;
                border-radius: 5px;
                position: relative;
                display: flex;
                justify-content: center;
                transition: all 0s;
                &:hover {
                    .tooltip-nav-link {
                        opacity: 1;
                        visibility: visible;
                    }
                }
                &.active {
                    background-color: #5571c6 !important;
                    .text-link {
                        color: #fff;
                    }
                }
                &.inactive {
                    background-color: transparent !important;
                    .text-link {
                        color: #000;
                    }
                }
                .group-text-link {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    .icon-link {
                        width: 20px;
                    }
                    .icon-down {
                        width: 24px;
                        transition: all 0.3s;
                        height: 24px;
                        position: absolute;
                        top: 50%;
                        right: 5px;
                        transform: translateY(-50%) rotate(0);
                        &.show {
                            transform: translateY(-50%) rotate(-180deg);
                        }
                    }
                    .text-link {
                        width: calc(100% - 20px - 24px);
                        padding-left: 16px;
                        position: absolute;
                        left: 35px;
                        font-size: 15px;
                        pointer-events: none;
                        &.textlink-erx {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            padding-right: 30px;
                            .icon-notification {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 25px;
                                height: 25px;
                                background: #fff;
                                color: #ffa500;
                                border-radius: 50%;
                                svg {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                    .icon-notification-small {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 15px;
                        height: 15px;
                        background: #ff3131;
                        color: #f7f7f7;
                        border-radius: 50%;
                        position: absolute;
                        top: 6px;
                        right: 8px;
                        svg {
                            font-size: 13px;
                        }
                    }
                }
                .tooltip-nav-link {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: calc(100%);
                    padding-left: 14px;
                    // transition: all 0.1s;
                    opacity: 0;
                    visibility: hidden;
                    .text-link-tt {
                        margin-left: 15px;
                        padding: 5px 10px;
                        background-color: #fff;
                        position: relative;
                        white-space: nowrap;
                        border-radius: 5px;
                        // box-shadow: 0 0 6px #0000003d;
                        border: solid 1px #5571c6;
                        color: #5571c6;
                        &::after {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translate(-100%, -50%);
                            width: 0;
                            height: 0;
                            border-top: 8px solid transparent;
                            border-bottom: 8px solid transparent;
                            border-right: 8px solid #5571c6;
                        }
                    }
                }
            }
            .wrap-navlink-2 {
                background: #e9e9e9;
                border-radius: 5px;
                // margin: 10px 0;
                margin-bottom: 15px;
                overflow: hidden;
                &.wrap-navlink-2-erx {
                    background: #fff;
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                    padding: 10px;
                    & > div {
                        &:last-child {
                            .nav-link-lv2 {
                                margin-bottom: 0;
                            }
                        }
                        .nav-link-lv2 {
                            padding: 0;
                            margin-bottom: 10px;
                            background: aliceblue;
                            border-radius: 5px;
                            &.active {
                                background: #a5d5ff;
                                // .text-link-lv2 {
                                //     font-weight: 500;
                                //     color: #278383;
                                // }
                            }
                            .text-link-lv2 {
                                padding-left: 10px;
                                width: 100%;
                            }
                        }
                    }
                }
                .nav-link-lv2 {
                    padding: 0 0 0 16px;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    height: 41px;
                    position: relative;
                    &.active {
                        // background-color: #849aad;
                        .text-link-lv2 {
                            color: #5571c6;
                        }
                    }
                    .icon-link-lv2 {
                        width: 8px;
                        height: 8px;
                    }
                    .text-link-lv2 {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 0;
                        width: calc(100% - 8px - 16px);
                        padding-left: 10px;
                    }
                    .wrap-notification {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 10px;
                        // padding-left: 10px;
                        font-size: 12px;
                        .lef {
                            display: inline-block;
                            background: #ff4f4f;
                            font-weight: 500;
                            color: #fff;
                            min-width: 15px;
                            height: 15px;
                            line-height: 15px;
                            text-align: center;
                            border-radius: 2px;
                            position: relative;
                            margin-right: 12px;
                            &:last-child {
                                margin-right: 0;
                                &::before {
                                    content: '';
                                    position: absolute;
                                    z-index: 666666;
                                    top: 0;
                                    left: -7px;
                                    width: 2px;
                                    height: 100%;
                                    background: #89898b;
                                }
                            }
                        }
                    }
                }
            }
            .wrap-navlink-2-tootip {
                position: absolute;
                top: 70px;
                left: calc(100% - 14px);
                height: calc(100vh - 70px);
                width: 200px;
                z-index: 99999999;
                opacity: 0;
                visibility: hidden;
                pointer-events: none;
                transition: all 0.2s;
                .wrap-ct-item {
                    margin-left: 14px;
                    background: #ebebeb;
                    background: #fff;
                    height: calc(100vh - 70px);
                    overflow: auto;
                    padding: 20px 0;
                    box-shadow: 4px 0px 7px #00000021;
                    .title-tooltip-cat {
                        padding: 6px 12px;
                        background: #5571c6;
                        color: #fff;
                        text-align: center;
                        margin-bottom: 20px;
                    }
                    .nav-link-lv2 {
                        // color: #667689;
                        display: block;
                        padding: 10px 12px;
                        transition: all 0.2s;
                        &:hover {
                            background-color: #c8d6ff;
                            p {
                                color: #5571c6;
                            }
                        }
                        &.active {
                            background-color: #c8d6ff;
                            p {
                                color: #5571c6;
                            }
                        }
                    }
                }
            }
        }
    }
    .btn-logout {
        padding: 0 14px;
        border-top: 1px solid #e9ecf1;
        .nav-wrap-logout {
            height: 35px;
            padding: 0 15px;
            display: flex;
            align-items: center;
            img {
                width: 20px;
            }
            .menu {
                flex-grow: 1;
                padding-left: 16px;
                color: #667689;
            }
        }
    }
    .icon-close-bar {
        position: absolute;
        top: 100px;
        left: 100%;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 28px;
        background: #5571c6;
        pointer-events: none;
        opacity: 0;
        visibility: hidden;
        transition: 0.2s;
        transition-delay: 0.2s;
        &.show-icon-close {
            opacity: 1;
            visibility: visible;
            top: 0;
        }
        svg {
            color: #fff;
            width: 20px;
            height: 20px;
        }
    }
}
.blur-bg {
    position: fixed;
    z-index: 99999;
    background: #000;
    width: 100%;
    height: 100%;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    background: rgba(0, 0, 0, 0.712);
    opacity: 0;
    visibility: hidden;
    transition: all 0.1s;
    &.show-blur-bg {
        opacity: 1;
        visibility: visible;
    }
}
