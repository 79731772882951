// .makeStyles-header-85 {
//     text-align: left !important;
//     padding: 1rem !important;
//     color: #303E4E !important;
//     font-family: Rubik;
//     font-size: 18px !important;
//     font-style: normal;
//     font-weight: 500 !important;
//     line-height: 140% !important;
//     border-bottom: 1px solid #DAD9D9 !important;
//     margin-top: 0 !important;
// }

.MuiCheckbox-colorSecondary.Mui-checked {
    color: #1976d2 !important;
}

.modal_padding {
    padding-bottom: 12px;
}

.MuiGrid-spacing-xs-3>.MuiGrid-item {
    padding: 12px;
    padding-bottom: 0px !important;
}

.myheader {
    text-align: left !important;
    padding: 1rem !important;
    color: #303E4E !important;
    font-family: Rubik;
    font-size: 18px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 140% !important;
    border-bottom: 1px solid #DAD9D9 !important;
    margin-top: 0 !important;
}