

.tag-color {
    width: 35px;
    height: 35px;
    border: 1px solid #e9ecf1;
    border-radius: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 6px;
}
.border-bottom {
    border-bottom: 1px solid #CFCFCF;
    margin-bottom: 7px;
}
// .date-range-for-announcement .css-1pduc5x-MuiStack-root>.MuiTextField-root {
//     // min-width: 131px;
// }
.calendar_container .calenderwrapper {
    position: unset !important
}

.page-title {
    margin-left: 25px;
    font-size: 22px;
    font-weight: 500;
    color: #303E4E;
}

.system_notice {
    .MuiDialog-container .MuiPaper-root {
        width: 800px !important;
        max-width: 800px !important;
        .MuiDialogContent-root {
            border: 1px solid rgb(225, 230, 219) !important;
            margin: 12px !important;
            border-radius: 5px !important;
            width: 725px !important;
            height: 394px !important;
        };
    }
    

}

.divider-information .MuiFormLabel-root {
    margin-bottom: 5px;
    margin-top: 3px;
}

.divider-information .MuiBox-root {
    margin-bottom: 5px;
}

fieldset.divider-information {
    border: 1px solid #CFCFCF;
    border-radius: 5px;
    margin-left: 6px;
    margin-bottom: 16px;
}
legend.heading-title{
    color: #667689;
    font-size: 0.875rem;
    font-family: Rubik;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0.01em;
    text-transform: inherit;
}
.heading1-title{
    font-family: Rubik;
    font-weight: 400;
    font-size: "18px";
    color: #303E4E !important;
}
.divider_selectBoxTaxonomy {
    height: 35px !important;
    width: 3px !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}
.heading1-title-licenses{
    font-family: Rubik;
    font-weight: 400;
    font-size: "16px";
    color: #667689 !important;
}
.MuiGrid-item {
    &.MuiGrid-grid-xs-1 {
        max-width: 7%;
        flex-basis: 7%;
    }
}
.divider_AccountTab{
    height: 20px !important;
    width: 2px !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-right: 8px !important;
    background-color: #5571C6 !important;
}
.modifier_top_state{
        border: 1px solid #D0D0D0;
        border-radius: 5px;
        justify-content: space-between;
        background-color: rgba(192, 193, 197, 0.10);
        padding: 5px 5px 0px 5px !important;
        
}
.add-button{
    font-family: 'Rubik';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin-right: 5px;
}