.wrap-form-availability {
    color: #303e4e;
    margin: 20px 40px;
    // border-top: solid 1px #dee2e6;
    padding-top: 13px;
    // height: 675px;
    display: flex;
    flex-direction: column;
    .content-time-zone {
        border-top: solid 1px #dee2e6;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .content-date-range {
        border-top: solid 1px #dee2e6;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .content-form-availability {
        flex-grow: 1;
        margin-bottom: 24px;
        border-radius: 5px;
        border: 1px solid #EAECEF;
        padding: 10px;
    }
    .sub-content-form-availability {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        align-items: center;
        gap: 15px
    }
    .group-check-label {
        margin-top: 12px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        padding-left: 10px;
        .btn-refresh {
            display: flex;
            cursor: pointer;
        }
    }
    .group-reoccurring {
        &.active {
            border: solid 1px #657cc8;
            pointer-events: visible;
            opacity: 1;
        }
        margin-top: 12px;
        opacity: 0.4;
        pointer-events: none;
        border: solid 1px #a5a5a5;
        padding: 23px;
        padding-bottom: 15px;
        border-radius: 5px;
        margin-bottom: 20px;
        .reoccurring-left {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }
}
.wrap-repeat-one {
    &.disabled {
        pointer-events: none;
        opacity: 0.7;
    }
    .list-btn-days {
        display: flex;
        .item-day {
            &.active {
                color: #fff;
                background: #657cc8;
            }
            color: #303e4e;
            width: 36px;
            height: 36px;
            border-radius: 50%;
            background: #d9d9d9;
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            cursor: pointer;
        }
    }
}
.ends-repeat {
    gap: 8px;
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    height: 100%;
    .group-radio-datePicker {
        display: flex;
        align-items: center;
        .left-radio {
            width: 78px;
        }
        .right-picker {
            width: 160px;
        }
    }
    .after {
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
            font-size: 14px;
        }
    }
}

.required_text {
    color: red;
}

.MuiPickersToolbar-toolbar {
    .MuiButton-root {
        background: transparent;
    }

    box-shadow: 0px 0px 6px 2px #c9c9c9;
    margin: 1px;
}
.fff-datepicker {
    .MuiIconButton-label {
        color: lightgray;

        svg {
            width: 1.4rem;
        }
    }
}

.timepicker {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    width: 42%;
    border: 1px solid rgb(152, 156, 168);
}

.timepicker .MuiGrid-root .MuiGrid-root {
    background-color: #ffffff !important;
    border: none !important;
    
    .MuiTypography-root {
        color: #303E4E !important;
        font-family: Rubik;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
    }
}

.columnRight {
    width: 50%;
    display: flex;
    gap: 10px;
    align-items: center;
}

.row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    border-bottom: 1px solid #F6F6F6;
    padding-bottom: 10px;
}

.columnLeft {
    width: 50%
}

.dateSection {
    display: flex;
    gap: 10px;
    width: 100% !important;
    overflow: hidden;
    justify-content: flex-end;
    .calenderwrapper {
        position: relative;
        width: 60%;
        div .calendarElement{
            position: fixed;
        }
        .MuiTextField-root {
            width: 100% !important
        }
    }
}

.no-button {
    color: rgb(255, 255, 255);
    background: #AE2020 !important;
    border-radius: 5px !important;
    height: 32px !important;
}


