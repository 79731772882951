@import '../../styles/themes/color.module.scss';

.patient-create,
.problem-create,
.insurance-form,
.insurance-plan-form {
    position: relative;
    overflow-y: auto;

    .MuiFormLabel-root {
        font-size: 14px;
        font-weight: 500;
    }

    .MuiInputBase-root {
        font-size: 14px;
    }

    .MuiSelect-select {
        .MuiTypography-body2 {
            font-size: 14px;
        }
    }

    li.MuiListItem-root > p {
        font-size: 14px;
    }

    .heading1-title {
        color: #5571c6;
    }

    fieldset.divider {
        width: 100%;
        border: 1px solid lightgray;
        border-bottom: none;
        border-left: none;
        border-right: none;
        margin-left: 6px;
        padding-inline-start: 0;
    }
    .text-button {
        display: flex;
        align-items: center;
        color: #40bd7b;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;

        svg {
            width: 22px;
            margin-right: 5px;
        }
    }

    .underline {
        width: 100%;
        height: 1px;
        border-bottom: 1px solid #d9d9d9;
        margin-top: 1rem;
    }

    .MuiFormLabel-root {
        margin-bottom: 5px;
        margin-top: 10px;
    }

    .MuiTypography-h4 {
        margin-bottom: 1rem;
    }
}

.MuiAutocomplete-option span.selected {
    background-color: #F3F6F9;
    color: #5571C6;
    font-weight: bold;
}

.highlight {
    em {
        color: red;
    }
}

.extra_hover_padding {
    padding: 5px;
}

.editable:hover {
    border: 1px solid #EAEAEA;
    border-radius: 5px;
    background-color: rgba(156, 158, 162, 0.15);
}

.on_edit_mode {
    box-sizing: border-box;
    outline: none;
    border: 2px solid #E2E3E5;
    border-radius: 5px;
    padding: 5px;
    font-family: Rubik;
    font-size: 14px;
    background-color: #fff;
    color: #303E4E;
}

.on_edit_mode[type=text]:focus {
    border: 2px solid #E2E3E5 !important;
    border-radius: 5px;
    padding: 5px;
}

.error_field, .error_field[type=text]:focus {
    border: 2px solid #e32929 !important;
}

.preferred_name_edit {
    font-weight: bold;
    color: #667689 !important;
}

.header_edit {
    font-size: 18px !important;
    font-family: "Roboto", sans-serif !important;
    font-weight: 700 !important;
}

.on_edit_icon_container {
    box-sizing: border-box !important;
    border: 2px solid #E2E3E5 !important;
    border-radius: 5px !important;
    color: #667689 !important;
    height: 25px;
    width: 25px;
}

.on_edit_icon {
    height: 15px !important;
    width: 15px !important;
}

.tabLeftMargin {
    .MuiBox-root .MuiTabs-root {
        margin-left: 20px !important
    }
}

.tooltip-tab {
    position: relative;
    display: inline-block;
    border: none;
    background-color: white;
    cursor: not-allowed;
}

.tooltip-tab::after {
    content: attr(title);
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    color: black;
    padding: 5px 10px;
    border-radius: 5px;
    opacity: 0;
    transition: opacity 0.3s;
    pointer-events: none;
}

.tooltip-tab:hover::after {
    opacity: 1;
}

.fax_pdf_preview_container {
    display: flex; 
    justify-content: center; 
    height: 95%; 
    align-items: center; 
    margin-bottom: 10px;
    .rpv-default-layout__toolbar {
        border-width: 1px 1px 1px 1px !important;
        border-style: solid !important;
        border-color: #DDE1E6 !important;
        border-radius: 8px 8px 0px 0px !important;
    };
    .rpv-default-layout__main {
        border-width: 0px 1px 1px 1px !important;
        border-style: solid !important;
        border-color: #DDE1E6 !important;
        border-radius: 0px 0px 8px 8px !important;
        flex-direction: column !important;
        align-items: center !important;
    };
    .rpv-default-layout__container {
        border-width: 0px !important;
    };
    .rpv-default-layout__sidebar-headers {
        // display: flex !important;
        // width: 100% !important;
        // align-items: center !important;
        display: none
    };
    .rpv-default-layout__sidebar--ltr {
        border-right: 0px !important;
        width: 100% !important;
    };
    .rpv-default-layout__body {
        width: 98% !important;
    }
}