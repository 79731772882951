.date-picker {
    margin-top: 8px !important;
    width: 100%;
    div {
        width: 100%;
        padding-right: 0;
        background: #fbfcfc;
        input {
            padding: 10.5px;
            border-radius: 5px;
        }
        div {
            width: 50px;
        }
    }
}

.loading-addendum {
    width: 100%;
    height: 100%;
    opacity: 0.7;
    position: absolute;
    background: #fff;
    z-index: 9;
    margin: auto;
}
.loader {
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 50%;
}
.select-date {
    background: #fbfcfc;
    div {
        height: 40px;
        font-size: 14px;
        font-weight: 400;
        input: {
            padding-left: 16px;
        }
    }
}
