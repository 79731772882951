.wrap-availability-provider {
    * {
        box-sizing: border-box;
    }
    .hearder-availability {
        // background: #f5f6f7;
        padding: 7px;
        padding-top: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .group-views {
            display: flex;
            align-items: center;
            .btn-views {
                height: 32px;
                line-height: 32px;
                width: 64px;
                color: #475569;
                text-align: center;
                cursor: pointer;
                border-radius: 6px;
                text-transform: capitalize;
                background: #eaeaea;
                margin-right: 10px;
                &.active {
                    // font-weight: 300;
                    color: #fff;
                    background: #5571c6;
                }
            }
        }
        .btn-label {
            background-color: #15a452;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            color: #fff;
            width: max-content;
            cursor: pointer;
            border-radius: 5px;
            padding: 0 16px 0 10px;
            svg {
                font-size: 23px;
                padding-right: 6px;
            }
        }
    }
    .wrap-calendar-provider {
        // display: flex;
        // flex-wrap: wrap;
        .left-calendar {
            position: relative;
            // width: calc(100% - 330px);
            .block-calendar {
                height: calc(100vh - 266px);
            }
        }
        // .right-calendar-small {
        //     width: 330px;
        //     display: flex;
        //     flex-direction: column;
        //     justify-content: flex-start;
        // }
        .rbc-toolbar {
            display: none;
        }
    }
    .in-clinical-note.wrap-calendar-provider {
        display: flex;
        flex-wrap: wrap;
        .left-calendar {
            position: relative;
            width: calc(100% - 330px);
            .block-calendar {
                height: calc(100vh - 266px);
            }
        }
        .right-calendar-small {
            width: 330px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
        }
        .rbc-toolbar {
            display: none;
        }
    }
}
