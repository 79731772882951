.group-check-label {
    margin-top: 16px;
    margin-bottom: 0px !important;
    display: flex;
    align-items: center;
    padding-left: 10px;

    .btn-refresh {
        display: flex;
        cursor: pointer;
    }
}

.group-reoccurring {
    &.active {
        border: solid 1px #657cc8;
        pointer-events: visible;
        opacity: 1;
    }

    margin-top: 6px;
    opacity: 0.4;
    pointer-events: none;
    border: solid 1px #a5a5a5;
    padding: 23px;
    padding-bottom: 15px;
    border-radius: 5px;
    margin-bottom: 20px;

    .reoccurring-left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.add-new-availability {
    color: #303E4E;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 13px;
    border-radius: 5px;

    span {
        display: flex;
        align-items: center;
        cursor: pointer;

        svg {
            font-size: 11px;
            margin-right: 6px;
        }
    }
}

.wrap-repeat-one {
    &.disabled {
        pointer-events: none;
        opacity: 0.7;
    }

    .list-btn-days {
        display: flex;

        .item-day {
            &.active {
                color: #fff !important;
                background: #657cc8 !important;
                font-weight: 400;
            }

            font-weight: 500;
            background-color: white !important;
            color: #657cc8 !important;
            border: 1px solid #657cc8;
            width: 36px;
            height: 36px;
            border-radius: 50%;
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            cursor: pointer;
        }
    }
}

.ends-repeat {
    gap: 8px;
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    height: 100%;

    .group-radio-datePicker {
        display: flex;
        align-items: center;

        .left-radio {
            width: 78px;
        }

        .right-picker {
            width: 160px;
        }
    }

    .after {
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
            font-size: 14px;
        }
    }
}

.new-scheduler-menu {
    .input-form-label {
        font-weight: 500;
        font-size: 14px !important;
        margin-bottom: 2px;
        margin-top: 0px;
        color: #667689;
        font-weight: 500 !important;
    }
}