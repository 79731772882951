@import '../../styles/themes/color.module.scss';
@charset "UTF-8";

.rbc-date-cell {
    font-size: 16px;
    font-family: 'Rubik', sans-serif;
    margin: 16px 16px 6px 16px;
    background: #f1f3f5;
    border-radius: 5px;
    text-align: left;
    line-height: 24px;

    flex: 1 1 0;
    min-width: 0;
    padding-right: 5px;
    // text-align: right;
}

.rbc-date-cell:last-child {
    background: #fbfdff;
}

.specialDay {
    background-color: #eff2f5;
}

.rbc-day-slot .rbc-timeslot-group .customSlot {
    // background-color: #C7D0DB;
    background-color: rgba(199, 208, 219, 0.2);
}

.rbc-timeslot-group:hover {
    // background-color: #C7D0DB !important;
    background-color: rgba(199, 208, 219, 0.25);
}

.rbc-time-slot.customSlot:hover {
    background-color: rgba(199, 208, 219, 0) !important;
}

.timeSlotSelect {
    background-color: rgba(199, 208, 219, 0.25);
    border: 2px solid #d0d4d9 !important;
}

.rbc-btn {
    color: inherit;
    font: inherit;
    margin: 0;
}

button.rbc-btn {
    overflow: visible;
    text-transform: none;
    -webkit-appearance: button;
    cursor: pointer;
}

button[disabled].rbc-btn {
    cursor: not-allowed;
}

button.rbc-input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

.rbc-calendar {
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.rbc-calendar *,
.rbc-calendar *:before,
.rbc-calendar *:after {
    box-sizing: inherit;
}

.rbc-abs-full,
.rbc-row-bg {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.rbc-ellipsis,
.rbc-event-label,
.rbc-row-segment .rbc-event-content,
.rbc-show-more {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    // white-space: nowrap;
}

.rbc-rtl {
    direction: rtl;
}

.rbc-off-range {
    color: #999999;
}

.rbc-off-range-bg {
    background: #fff;
}

.rbc-header {
    overflow: hidden;
    flex: 1 0 0%;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 3px;
    text-align: left;
    vertical-align: middle;
    font-weight: bold;
    font-size: 90%;
    min-height: 0;
    border-bottom: 1px solid #ddd;
    // min-width: 351px;
    // min-width: calc(100%/4)
}

.rbc-rtl .rbc-header + .rbc-header {
    border-left-width: 0;
    border-right: 1px solid #ddd;
}

.rbc-header > a,
.rbc-header > a:active,
.rbc-header > a:visited {
    color: inherit;
    text-decoration: none;
}

.rbc-row-content {
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    /* z-index: 1; */
}

.rbc-row-content-scrollable {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.rbc-row-content-scrollable .rbc-row-content-scroll-container {
    height: 100%;
    overflow-y: scroll;
    /* Hide scrollbar for Chrome, Safari and Opera */
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.rbc-row-content-scrollable .rbc-row-content-scroll-container::-webkit-scrollbar {
    display: none;
}

.rbc-today {
    // background-color: #eaf6ff;
    /* background-color:rgba(85,113,198,0.1); */
}

.rbc-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    font-size: 16px;
}

.rbc-toolbar .rbc-toolbar-label {
    flex-grow: 1;
    padding: 0 10px;
    text-align: center;
}

// .rbc-toolbar button {
//     color: #373a3c;
//     display: inline-block;
//     margin: 0;
//     text-align: center;
//     vertical-align: middle;
//     background: none;
//     background-image: none;
//     border: 1px solid #ccc;
//     padding: 0.375rem 1rem;
//     border-radius: 4px;
//     line-height: normal;
//     white-space: nowrap;
// }

// .rbc-toolbar button:active,
// .rbc-toolbar button.rbc-active {
//     background-image: none;
//     box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
//     background-color: #e6e6e6;
//     border-color: #adadad;
// }

// .rbc-toolbar button:active:hover,
// .rbc-toolbar button:active:focus,
// .rbc-toolbar button.rbc-active:hover,
// .rbc-toolbar button.rbc-active:focus {
//     color: #373a3c;
//     background-color: #d4d4d4;
//     border-color: #8c8c8c;
// }

// .rbc-toolbar button:focus {
//     color: #373a3c;
//     background-color: #e6e6e6;
//     border-color: #adadad;
// }

// .rbc-toolbar button:hover {
//     color: #373a3c;
//     background-color: #e6e6e6;
//     border-color: #adadad;
// }

.rbc-btn-group {
    display: inline-block;
    white-space: nowrap;
}

.rbc-btn-group > button:first-child:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.rbc-btn-group > button:last-child:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rbc-rtl .rbc-btn-group > button:first-child:not(:last-child) {
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rbc-rtl .rbc-btn-group > button:last-child:not(:first-child) {
    border-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.rbc-btn-group > button:not(:first-child):not(:last-child) {
    border-radius: 0;
}

.rbc-btn-group button + button {
    margin-left: -1px;
}

.rbc-rtl .rbc-btn-group button + button {
    margin-left: 0;
    margin-right: -1px;
}

.rbc-btn-group + .rbc-btn-group,
.rbc-btn-group + button {
    margin-left: 10px;
}

.rbc-slot-selecting .rbc-event,
.rbc-slot-selecting .rbc-day-slot .rbc-background-event,
.rbc-day-slot .rbc-slot-selecting .rbc-background-event {
    cursor: inherit;
    pointer-events: none;
}

.rbc-event.rbc-selected,
.rbc-day-slot .rbc-selected.rbc-background-event {
    // background-color: #eef0f9;
}

.rbc-event:focus,
.rbc-day-slot .rbc-background-event:focus {
    outline: 5px auto #eef0f9;
}

.rbc-event-label {
    font-size: 80%;
}

.rbc-event-overlaps {
    box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5);
}

.rbc-event-continues-prior {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rbc-event-continues-after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.rbc-event-continues-earlier {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.rbc-event-continues-later {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.rbc-row {
    display: flex;
    flex-direction: row;
}

.rbc-selected-cell {
    background-color: rgba(0, 0, 0, 0.1);
}

.rbc-show-more {
    z-index: 4;
    font-size: 12px;
    height: auto;
    line-height: normal;
    padding: 6px 16px;
    background: none;
    border: none;
    cursor: pointer;
    margin-top: 6px;
    font-family: 'Rubik';
    color: #0076fc;
}

.rbc-month-view {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    width: 100%;
    user-select: none;
    -webkit-user-select: none;
    height: 100%;
}

.rbc-month-header {
    display: flex;
    flex-direction: row;
    background-color: #f8f9fb;
}

.rbc-month-row {
    display: flex;
    position: relative;
    flex-direction: column;
    flex: 1 0 0;
    flex-basis: 0px;
    overflow: hidden;
    height: 100%;
}

.rbc-month-row + .rbc-month-row {
    border-top: 1px solid #ddd;
}

.rbc-date-cell.rbc-now {
    // font-weight: bold;
}

.rbc-date-cell > a,
.rbc-date-cell > a:active,
.rbc-date-cell > a:visited {
    color: inherit;
    text-decoration: none;
}

.rbc-row-bg {
    display: flex;
    flex-direction: row;
    flex: 1 0 0;
    overflow: hidden;
}

.rbc-day-bg {
    flex: 1 0 0%;
}

.rbc-day-bg + .rbc-day-bg {
    border-left: 1px solid #ddd;
}

.rbc-rtl .rbc-day-bg + .rbc-day-bg {
    border-left-width: 0;
    border-right: 1px solid #ddd;
}

.rbc-overlay {
    position: absolute;
    max-height: 40%;
    overflow: auto;
    z-index: 5;
    border: 1px solid #e5e5e5;
    background-color: #fff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
    padding: 10px;
}

.rbc-overlay > * + * {
    margin-top: 1px;
}

.rbc-overlay-header {
    border-bottom: 1px solid #e5e5e5;
    margin: -10px -10px 5px -10px;
    padding: 2px 10px;
}

.rbc-agenda-view {
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    overflow: auto;
}

.rbc-agenda-view table.rbc-agenda-table {
    width: 100%;
    border: 1px solid #ddd;
    border-spacing: 0;
    border-collapse: collapse;
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
    padding: 5px 10px;
    vertical-align: top;
}

.rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
    padding-left: 15px;
    padding-right: 15px;
    text-transform: lowercase;
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
    border-left: 1px solid #ddd;
}

.rbc-rtl .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
    border-left-width: 0;
    border-right: 1px solid #ddd;
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
    border-top: 1px solid #ddd;
}

.rbc-agenda-view table.rbc-agenda-table thead > tr > th {
    padding: 3px 5px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.rbc-rtl .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
    text-align: right;
}

.rbc-agenda-time-cell {
    text-transform: lowercase;
}

.rbc-agenda-time-cell .rbc-continues-after:after {
    content: ' »';
}

.rbc-agenda-time-cell .rbc-continues-prior:before {
    content: '« ';
}

.rbc-agenda-date-cell,
.rbc-agenda-time-cell {
    white-space: nowrap;
}

.rbc-agenda-event-cell {
    width: 100%;
}

.rbc-time-column {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.rbc-timeslot-group {
    border-bottom: 1px solid #ddd;
    min-height: 46px;
    display: flex;
    flex-flow: column nowrap;
    z-index: 2;
    // width: 100%
}

.rbc-time-gutter,
.rbc-header-gutter {
    flex: none;
}

.rbc-label {
    padding: 0 5px;
}

.rbc-day-slot {
    position: relative;
}

.rbc-day-slot .rbc-events-container {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    margin-right: 10px;
    top: 0;
}

.rbc-day-slot .rbc-events-container.rbc-rtl {
    left: 10px;
    right: 0;
}

.rbc-day-slot .rbc-background-event {
    display: flex;
    margin: 4px 16px;
    max-height: 100%;
    min-height: 20px;
    flex-flow: column wrap;
    align-items: flex-start;
    overflow: hidden;
    position: absolute;
}

.rbc-day-slot .rbc-event {
    display: flex;
    max-height: 100%;
    min-height: 20px;
    flex-flow: column wrap;
    align-items: flex-start;
    overflow: hidden;
    position: absolute;
    // z-index:2;
}

.rbc-day-slot .rbc-background-event {
    opacity: 0.75;
}

.rbc-day-slot .rbc-event-label {
    flex: none;
    padding-right: 5px;
    width: auto;
}

.rbc-day-slot .rbc-event-content {
    width: 100%;
    flex: 1 1 0;
    word-wrap: break-word;
    line-height: 1;
    height: 100%;
    min-height: 1em;
    z-index: 2;
    position: relative;

}

.rbc-day-slot .rbc-time-slot {
    border-top: 1px solid #f7f7f7;
}

.rbc-time-view-resources .rbc-time-gutter,
.rbc-time-view-resources .rbc-time-header-gutter {
    position: sticky;
    left: 0;
    background-color: white;
    border-right: 1px solid #ddd;
    z-index: 10;
    margin-right: -1px;
}

// %rbc-time-view-resources .rbc-time-gutter,
// .rbc-time-view-resources .rbc-time-header-gutter {
//     position: sticky;
//     left: 0;
//     background-color: white;
//     border-right: 1px solid #ddd;
//     z-index: 10;
//     margin-right: -1px;
// }

.rbc-time-header-gutter {
    height: 100%;
    border-bottom: 1px solid $secondaryGray;
}

.rbc-time-view-resources .rbc-time-header {
    overflow: hidden;
}

.rbc-time-view-resources .rbc-time-header-content {
    min-width: 351px;
    // min-width: auto;
    flex: 1 0 0;
    flex-basis: 0px;
}

.rbc-time-view-resources .rbc-time-header-cell-single-day {
    display: none;
}

.rbc-time-view-resources .rbc-day-slot {
    min-width: 140px;
}

.rbc-time-view-resources .rbc-header,
.rbc-time-view-resources .rbc-day-bg {
    width: 140px;
    flex: 1 1 0;
    flex-basis: 0 px;
}

.rbc-time-header-content + .rbc-time-header-content {
    margin-left: -1px;
}

.rbc-time-slot {
    flex: 1 0 0;
    display: flex;
    // align-items: flex-end;
    justify-content: center;
}

.rbc-time-slot.rbc-now {
    font-weight: bold;
}

.rbc-day-header {
    text-align: center;
}

.rbc-slot-selection {
    z-index: 10;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 75%;
    width: 100%;
    padding: 3px;
}

.rbc-slot-selecting {
    cursor: move;
}

.rbc-time-view {
    // position: static;
    display: flex;
    flex-direction: column;
    flex: 1;
    // min-width: cal(100%/4);
    width: 100%;
    min-height: 0;
    // max-width: 80%;
    // position: sticky;
    @extend .rbc-time-view-resources;
}

.rbc-time-view .rbc-time-gutter {
    white-space: nowrap;
}

.rbc-time-view .rbc-allday-cell {
    box-sizing: content-box;
    width: 100%;
    height: 100%;
    position: relative;
}

.rbc-time-view .rbc-allday-cell + .rbc-allday-cell {
    border-left: 1px solid #ddd;
}

.rbc-time-view .rbc-allday-events {
    position: relative;
    z-index: 4;
}

.rbc-time-view .rbc-row {
    box-sizing: border-box;
    min-height: 20px;
}

.rbc-time-header {
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
}

.rbc-rtl .rbc-time-header.rbc-overflowing {
    border-right-width: 0;
    border-left: 1px solid #ddd;
}

.rbc-time-header > .rbc-row:first-child {
    border-bottom: 1px solid #ddd;
}

.rbc-time-header > .rbc-row.rbc-row-resource {
    border-bottom: 1px solid #ddd;
}

.rbc-time-header-cell-single-day {
    display: none;
}

.rbc-time-header-content {
    flex: 1;
    display: flex;
    min-width: 0;
    flex-direction: column;
    background: #f8f9fb;
}

.rbc-rtl .rbc-time-header-content {
    border-left-width: 0;
    border-right: 1px solid #ddd;
}

.rbc-time-header-content > .rbc-row.rbc-row-resource {
    border-bottom: 1px solid #ddd;
    flex-shrink: 0;
}

.rbc-time-content {
    display: flex;
    flex: 1 0 0%;
    align-items: flex-start;
    width: 100%;
    border-top: 2px solid #ddd;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    // overflow-x: scroll;
}

.rbc-time-content > .rbc-time-gutter {
    flex: none;
    // border-left: 1px solid;
    border-right: 1px solid;
    border-color: $secondaryGray;
}

.rbc-time-content > * + * > * {
    border-right: 1px solid #ddd;
}

.rbc-rtl .rbc-time-content > * + * > * {
    border-left-width: 0;
    border-right: 1px solid #ddd;
}

.rbc-time-content > .rbc-day-slot {
    // width: 100%;
    min-width: 351px;
    overflow-x: scroll;
    user-select: none;
    -webkit-user-select: none;
}

.rbc-current-time-indicator {
    position: absolute;
    // z-index: 2;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #14ae5c;
    pointer-events: none;

    &::after {
        left: -3px;
        top: -2px;
        content: '';
        width: 5px;
        height: 5px;
        border-radius: 50%;
        position: absolute;
        background: #14ae5c;
    }

    &::before {
        right: -1px;
        top: -2px;
        content: '';
        width: 5px;
        height: 5px;
        border-radius: 50%;
        position: absolute;
        background: #14ae5c;
    }
}

.timeoff-dropdown {
    border: 1px solid #c7c6c6 !important;
    border-radius: 4px;
    background-color: $primaryWhite !important;
}

.example {
    font-size: 14px;
    // padding: 0 40px;
    // min-height: calc(100vh - 100px);
    // height: calc(100vh - 100px);
    width: 100%;

    &,
    & > * {
        display: flex;
        flex-direction: column;
    }

    .rbc-calendar {
        flex: 1;

        // min-height: calc(100vh - 208px);
        .MuiGrid-container {
            align-items: center;
        }

        .mon_year_wrapper {
            // margin-right: 10px;
        }

        .typography18 {
            font-size: 14px;
            line-height: 24px;
            font-weight: bold;
            color: $primaryDarkBlue;
        }

        .rbc-month-view,
        .rbc-time-view {
            border-color: $secondaryGray;
        }

        .rbc-month-view {
            .rbc-month-row + .rbc-month-row {
                border-top: none;
            }
        }

        .rbc-header {
            // border-color: $secondaryGray;
            // border-left: 1px solid $secondaryGray;
            .rbc-button-link {
                border: none;
                border-radius: 5px;
                border: solid 1px #c1c1c1;
                width: 100%;
            }

            .MuiGrid-container {
                align-items: center;
            }
        }

        .MuiAutocomplete-paper {
            z-index: 100000 !important;
            background-color: red;
        }

        .rbc-day-bg + .rbc-day-bg {
            border-color: $secondaryGray !important;
            // border-left: none !important;
        }

        .rbc-row-bg {
            border-bottom: 1px solid $secondaryGray !important;
            border-left: 1px solid $secondaryGray !important;
            border-right: 1px solid $secondaryGray !important;

            .rbc-day-bg:last-child {
                background-color: #eff2f5 !important;
            }
        }

        .rbc-off-range {
            a {
                color: #ddd !important;
                pointer-events: none;
            }
        }

        .rbc-row .rbc-date-cell a {
            color: $primaryDarkBlue;
        }

        .rbc-event-content p b {
            color: $primaryDarkBlue;
            font-size: 15px;
            line-height: 17px;
        }

        .time-off-wrap p b {
            color: #132044;
        }

        .calendar_select_wrap {
            justify-content: flex-start;

            .MuiOutlinedInput-root {
                height: 40px;
                // .MuiSelect-outlined.MuiSelect-outlined {
                //     font-size: 18px;
                //     line-height: 21px;
                //     font-weight: bold;
                //     border-radius: 4px;
                //     &.MuiInput-underline:before {
                //         border-bottom: none;
                //     }
                // }
            }

            .MuiInputBase-root.MuiOutlinedInput-root.select_dd {
                width: 150px;
            }
        }

        .mon_year_wrapper {
            button.MuiButtonBase-root.MuiIconButton-root {
                border: 1px solid $secondaryGray;
                border-radius: 0;
                height: 35px;
                width: 35px;
                cursor: pointer;
                justify-content: center;
                align-items: center;
            }

            .date_wrap {
                font-size: 18px;
                line-height: 21px;
                color: $primaryDarkBlue;
                border: 1px solid $secondaryGray;
                height: 35px;
                font-weight: bold;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 10px;
                // margin: 0 10px;
                min-width: 110px;
            }

            .iconStretch {
                justify-content: stretch;

                svg {
                    left: 5px;
                    top: 0;
                    position: relative;
                }
            }

            .icon_next {
                margin-left: 5px;
            }

            svg.MuiSvgIcon-root {
                color: $primaryDarkBlue;
                font-size: 16px;
                font-weight: bold;
            }

            button {
                margin: 0;
                padding: 0;
                border-radius: 0;
            }
        }

        /* Month view header weekdays */
        .rbc-month-header {
            .rbc-header {
                height: 49px;
                align-items: center;
                justify-content: flex-start;
                display: flex;
                padding: 0;
                position: relative;

                .MuiBox-root {
                    font-size: 14px;
                    line-height: 24px;
                    color: $primaryDarkBlue;
                    padding: 0 6px;
                }
            }
        }

        /** timeoff spacers **/
        .rbc-month-row {
            .rbc-row {
                .rbc-event {
                    min-height: 30px;
                }
            }
        }

        .rbc-event-content .time-off-wrap {
            padding: 4px 6px;
        }

        /* Week view Header weekdays with day */

        .rbc-time-view {
            .rbc-time-header.rbc-overflowing {
                align-items: center;
                justify-content: flex-start;
                display: flex;
                padding: 0;
                position: relative;

                .MuiBox-root {
                    font-size: 14px;
                    line-height: 24px;
                    color: $primaryDarkBlue;
                    padding: 0 20px;
                }

                .date {
                    font-size: 21px;
                }
            }
        }

        .event-wrap {
            &.time-off {
                background: #e3e3e3;
                justify-content: center;
                text-align: center;

                .MuiTypography-body1,
                b {
                    color: $primaryDarkBlue;
                }
            }

            .slotType {
                position: relative;
                right: 5px;

                span {
                    width: 30px;
                    height: 30px;
                    background: #dce2fd;
                    border-radius: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: $primaryDarkBlue;
                }
            }
        }

        .rbc-event {
            // overflow-x: scroll;
            padding: 0;
        }

        .currentDay {
            .MuiBox-root {
                width: 100%;
                height: 10px;
                padding: 0px;
                position: absolute;
                bottom: 0px;
                width: 100%;
                left: 0;
                border-bottom: 8px solid $primaryDarkBlue;
                background-color: transparent;
            }
        }

        .btn-outline {
            border: 1px solid $primaryDarkBlue;
            width: 96px;
            height: 40px;
            font-size: 15px;
            line-height: 17px;
            font-weight: bold;
            color: $primaryDarkBlue;
        }

        //    .rbc-row-bg :not(first-child) {
        //     border-right: 1px solid $secondaryGray;
        //     }

        /* WEEK VIEW STYLES */

        /* Event colors */
        .rbc-day-slot .rbc-events-container {
            margin-right: 0;

            // width: 352px;
            .rbc-event {
                border: none;
                border-radius: 4px;
                width: 100%;
                overflow-x: scroll;

                .rbc-event-content {
                    padding: 0px;
                    width: fit-content; // FIXME:
                    // background: #f1f8ff;
                    // background: #d1c4e9;
                }
            }
        }

        .week-view {
            padding: 5px;
            color: $primaryDarkBlue;
        }

        .rbc-time-header-content {
            .rbc-header {
                height: 40px;
                align-items: center;
                justify-content: center;
                display: flex;

                .MuiGrid-item {
                    .MuiTypography-body1 {
                        font-size: 14px;
                        line-height: 24px;
                        color: $primaryDarkBlue;
                        font-weight: 500;

                        // font-weight:bold;
                        &.font-small {
                            font-size: 14px;
                            line-height: 24px;
                        }

                        &.font-medium {
                            font-size: 21px;
                            line-height: 24px;
                        }
                    }
                }
            }
        }

        // .rbc-time-content > * + * > * {
        //     border-left: 1px solid $secondaryGray;
        // }
        /*** TIME SLOT ***/
        .rbc-time-content {
            overflow-x: scroll;

            /** Week slot details **/
            .rbc-day-slot .rbc-event-content {
                padding: 3px 10px;
                display: flex;

                span.week-time-slot {
                    width: 20px;
                    height: 20px;
                    background: #dce2fd;
                    border-radius: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: $primaryDarkBlue;
                    margin-left: 5px;
                }
            }

            .rbc-event-content {
                padding: 10px 10px;
                color: $primaryDarkBlue;
                font-size: 14px;
                font-weight: bold;
            }
        }

        /*** Hide the time slot header **/
        .rbc-time-view {
            .rbc-allday-cell {
                display: none;
            }

            .rbc-time-content {
                border-top: none;
            }
        }

        .rbc-header.rbc-today {
            position: relative;
            // border-bottom: 6px solid $primaryDarkBlue;
        }
    }

    .time-zone {
        min-width: 222px;
        margin-right: 10px;
        color: $primaryDarkBlue;
        font-size: 15px;
        line-height: 24px;
    }
}

.rbc-date-cell .appointmentCount {
    width: 30px;
    height: 30px;
    background: #dce2fd;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $primaryDarkBlue;
    margin-left: 9px;
}

/* Modal Styles */
.calendarModal {
    header {
        border-radius: 4px 4px 0 0;
        background-color: $primaryDarkBlue;
        height: 43px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;

        span {
            color: $primaryWhite;
            font-size: 18px;
            line-height: 29px;
        }
    }

    main {
        padding: 20px;

        .details-wrap {
            border-radius: 4px;
            min-height: 36px;
            display: flex;
            align-items: center;
            padding: 10px 15px;
            margin-bottom: 20px;

            .bar {
                border-right: 2px solid $primaryDarkBlue;
                margin-right: 10px;
            }

            .time {
                font-size: 15px;
                line-height: 18px;
                font-weight: bold;
            }

            .field1 {
                font-size: 12px;
                padding-right: 10px;
                border-right: 1px solid #111;
                margin-right: 10px;
                line-height: 13px;
            }

            .tele-field,
            .clinic-field {
                font-size: 12px;
                line-height: 13px;
            }
        }
    }
}

/** TimeOff Centered **/
.MuiGrid-root.week-time-off {
    position: relative;
    top: 50%;
}

.rbc-time-view {
    .week-view-time {
        font-size: 13px;
    }

    .font13 {
        font-size: 13px;
    }
}

/** Day View - To increase the height of the cell **/
.rbc-time-column {
    min-height: 100px;
    flex: 1;
}

// .rbc-timeslot-group {
//     min-height: 45px;
//     flex:1
// }

.day-event-wrap {
    span.time {
        padding-right: 10px;
        border-right: 2px solid #111;
        margin-right: 10px;
    }

    span.title {
        padding: 1px 10px 1px 0;
        border-right: 1px solid #111;
        margin-right: 10px;
        line-height: 15px;
        font-size: 13px;
    }

    .appointment-type {
        line-height: 15px;
        font-size: 13px;
    }
}

/**** ****/

.tooltip-wrapper {
    color: $primaryDarkBlue;

    .date {
        font-size: 18px;
        line-height: 27px;
        font-weight: bold;
        border-right: 1px solid #111;
        padding-right: 10px;
        margin-right: 10px;
        margin-bottom: 20px;
    }

    .time {
        font-size: 18px;
        line-height: 27px;
        font-weight: bold;
    }

    .gray {
        color: $primaryGray;
        font-size: 15px;
        line-height: 34px;
        margin-right: 5px;
    }
}

.req-time-off-btn.MuiButton-containedPrimary {
    margin-left: 10px;
}

.MuiInputBase-root.MuiOutlinedInput-root.select_dd.fullWidth {
    width: 100% !important;
}

@media only screen and (max-width: 1550px) {
    .rbc-date-cell .appointmentCount {
        margin-left: 5px;
    }
}

@media only screen and (max-width: 1490px) {
    .example {
        .rbc-calendar .btn-outline {
            width: 65px;
            height: 35px;

            .typography18 {
                font-size: 15px;
            }
        }

        .rbc-calendar .calendar_select_wrap {
            .MuiInputBase-root.MuiOutlinedInput-root.select_dd {
                width: 80px;
            }
        }

        .req-time-off-btn.MuiButton-containedPrimary {
            margin-left: 7px;
            padding: 6px 6px;
        }
    }
}

@media only screen and (max-width: 1360px) {
    .rbc-date-cell .appointmentCount {
        margin-left: 3px;
    }

    .example .rbc-calendar {
        .rbc-event-content p b {
            font-size: 14px;
        }

        .mon_year_wrapper .date_wrap,
        .typography18 {
            font-size: 16px;
        }
    }
}

@media only screen and (max-width: 1280px) {
    .example .rbc-calendar {
        .rbc-event-content p b {
            font-size: 13px;
        }

        .rbc-event-content .time-off-wrap {
            font-size: 13px;
        }

        .rbc-date-cell .appointmentCount {
            width: 25px;
            height: 25px;
            font-size: 13px;
        }

        .rbc-row .rbc-date-cell a {
            font-size: 15px;
        }

        .main-wrapper {
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: flex-start;
            width: 100%;

            .date-wrapper,
            .left-wrapper {
                max-width: 100%;
            }
        }
    }
}

@media only screen and (max-width: 1180px) {
    .example .rbc-calendar .event-wrap {
        .time {
            padding-left: 0;
        }

        .slotType span {
            width: 20px;
            height: 20px;
            font-size: 13px;
            margin-left: 2px;
        }
    }

    .rbc-date-cell .appointmentCount {
        width: 20px;
        height: 20px;
        font-size: 13px;
        margin-left: 2px;
    }
}

@media only screen and (max-width: 1024px) {
    .example .rbc-calendar {
        .typography18 {
            font-size: 14px;
        }

        .mon_year_wrapper .date_wrap {
            font-size: 14px;
        }

        .mon_year_wrapper {
            margin-bottom: 10px;
            margin-top: 10px;
        }

        .example .rbc-calendar .calendar_select_wrap .MuiOutlinedInput-root {
            height: 30px;

            p.MuiTypography-root {
                font-size: 14px;
            }
        }

        .calendar_select_wrap .MuiOutlinedInput-root.MuiInputBase-fullWidth //     .MuiSelect-outlined.MuiSelect-outlined {

        //     font-size: 14px;
        // }
        .btn-outline {
            width: auto;
            padding: 5px 10px;
        }

        .MuiGrid-spacing-xs-2 > .MuiGrid-item {
            padding: 0 8px 8px;
        }

        .MuiGrid-container {
            // margin-top: 5px !important;
            // margin-bottom: 5px !important;
        }

        .rbc-month-header .rbc-header .MuiBox-root {
            font-size: 14px;
        }

        .currentDay .MuiBox-root {
            border-bottom: 6px solid $primaryDarkBlue;
        }

        .rbc-time-header-content .rbc-header {
            height: 65px;
            align-items: flex-start;

            .MuiGrid-item {
                .MuiTypography-body1 {
                    &.font-medium {
                        font-size: 14px;
                        line-height: 24px;
                    }

                    &.font-small {
                        font-size: 13px;
                        line-height: 19px;
                    }
                }
            }

            .MuiBox-root {
                padding-top: 5px;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .example .rbc-calendar .rbc-month-header .rbc-header .MuiBox-root {
        font-size: 16px;
        line-height: 18px;
        padding: 0 10px;
    }

    .example .rbc-calendar {
        .rbc-time-header-content .rbc-header {
            height: 60px;

            .MuiGrid-item {
                .MuiTypography-body1 {
                    &.font-medium {
                        font-size: 16px;
                        line-height: 22px;
                    }

                    &.font-small {
                        font-size: 12px;
                        line-height: 16px;
                    }
                }
            }

            .MuiBox-root {
                padding: 2px 5px 0px !important;
            }
        }
    }

    .MuiInputBase-root.MuiOutlinedInput-root.select_dd {
        width: 40% !important;
    }

    .example .rbc-calendar .calendar_select_wrap {
        justify-content: flex-end !important;
    }

    .example .rbc-calendar .calendar_select_wrap .MuiOutlinedInput-root {
        height: 30px;

        .MuiTypography-body1 {
            font-size: 14px;
        }
    }

    .example .req-time-off-btn.MuiButton-containedPrimary {
        margin-left: 7px;
        padding: 3px 6px;
    }
}

@media only screen and (max-width: 520px) {
    .example {
        .rbc-calendar .mon_year_wrapper {
            margin-right: 0;
        }

        .mon_year_wrapper {
            max-width: 100%;
            flex-basis: 100%;
            margin-right: 0;
        }
    }
}

@media only screen and (max-width: 580px) {
    .example .rbc-calendar .rbc-month-header .rbc-header .MuiBox-root {
        font-size: 14px;
        line-height: 16px;
        padding: 0 5px;
    }
}

@media only screen and (max-width: 375px) {
    .example .rbc-calendar {
        .typography18 {
            font-size: 12px;
        }

        .btn-outline {
            height: 30px;
        }

        .mon_year_wrapper {
            .date_wrap {
                font-size: 12px;
                height: 25px;
            }

            button.MuiButtonBase-root.MuiIconButton-root {
                height: 25px;
                width: 25px;
            }
        }

        .calendar_select_wrap {
            .MuiOutlinedInput-root.MuiInputBase-fullWidth //     .MuiSelect-outlined.MuiSelect-outlined {

            //     font-size: 12px;
            //     padding: 3px 14px !important;
            // }
            .MuiOutlinedInput-root.MuiInputBase-fullWidth {
                height: 30px;
            }
        }

        .MuiGrid-spacing-xs-2 > .MuiGrid-item {
            padding: 0 8px 4px;
        }

        .currentDay .MuiBox-root {
            border-bottom: 4px solid $primaryDarkBlue;
        }

        .rbc-month-header .rbc-header {
            height: 39px;

            .MuiBox-root {
                font-size: 13px;
            }
        }
    }
}

@media only screen and (max-width: 320px) {
    .example .rbc-calendar {
        .rbc-time-header-content .rbc-header {
            height: 50px;

            .MuiGrid-item {
                .MuiTypography-body1 {
                    &.font-medium {
                        font-size: 12px;
                        line-height: 16px;
                    }
                }
            }

            .MuiBox-root {
                padding: 2px 0 0 !important;
            }
        }
    }
}

.main-schedule {
    display: flex;
    flex-wrap: wrap;
    height: calc(100vh - 70px);
    overflow-y: hidden;
    overflow-x: hidden;
    z-index: 1;
    position: relative;

    * {
        box-sizing: border-box;
    }

    .schedule-left {
        width: calc(100% - 320px);
        position: relative;
        // padding: 20px;
        // padding-top: 5px;

        @media (max-width: 1280px) {
            width: 100%;
        }
    }

    .schedule-right {
        width: 320px;
    }
}

.wrap-calendar {
    max-width: 92vw;
    // max-width: 62vw;
    background: #f8f9fb;
    border: 1px solid #eaecf0;
    border-radius: 5px;
    margin: 0px 20px;

    .block-calendar {
        height: calc(100vh - 272px);
    }

    .rbc-toolbar {
        display: none;
    }
}

.rbc-time-header-cell .rbc-header {
    min-width: 351px;
}

.cancel-btn {
    background-color: transparent !important;
}

.cancel-btn:hover {
    background-color: transparent !important;
}

.custom-resource-title {
    display: flex;
    align-items: center;
    justify-content: between;
    padding: 0 10px;
    width: 100%;
    border: 2px solid #999;
    box-shadow: 2px 2px 5px #999;
    border-radius: 5px;
    position: relative;
    background-color: #fff;
}

.custom-resource-title:hover {
    border: 2px solid rgb(59, 97, 214);

    .drag-icon {
        opacity: 1;
    }
}

.drag-icon {
    position: absolute;
    height: 12px;
    top: 0;
    left: -4px;
    opacity: 0;
}

.calendar-overlay {
    animation: overlay 8s;
}

@keyframes overlay {
    0% {
        background: white;
    }
    50% {
        background: #aaa;
    }
    100% {
        background: white;
    }
}

.rbc-time-header .rbc-label {
    background-color: #f8f9fb;
    border-right: none;
}

.top-panel-calender-icon {
    height: 17px !important;
    width: 20px !important;
    cursor: pointer !important;
    // margin-left: 10px !important;
    color: #667689 !important;
}

.top-panel-calender-icon:hover {
    height: 23px !important;
    width: 27px !important;
}

.view_tab_parent .MuiTabs-root {
    min-height: 35px !important;
}
.view_tab_parent .MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer .MuiTab-root {
    height: 35px !important;
    min-height: 35px !important;
}

.rbc-addons-dnd-resizable {
    height: 100%;
}

.calendar-top-panel-multifilter-container {
    button {
        max-height: 35px;
        height: 35px !important;
    }
}

.event-card-details-popover .MuiPaper-root {
    box-shadow: none !important;
}

.notification-tooltip-display-none {
    display: none;
}

.notification-tooltip-display-on {
    display: initial;
}

.schedule-page-clinical-practice-tooltip .MuiTooltip-tooltip {
    font-size: 12px !important;
    padding: 5px 12px !important;
    color: #242424 !important;
}
