.substance-abuse-form {
    .MuiGrid-item > p,
    .MuiFormControl-fullWidth > p {
        margin-bottom: 2px;
    }
    .MuiFormHelperText-root {
        text-transform: none;
    }
    .MuiGrid-spacing-xs-3 > .MuiGrid-item:not(:last-child) {
        padding-bottom: 0;
    }
}
.wrap-select-has-loading {
    position: relative;
    .loading-select {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 9px;
        right: 28px;
        z-index: 999;
    }
}
