@import '../../../styles/themes/color.module.scss';
.app_header {
    background-color: $primaryWhite;
    display: flex;
    height: 70px;
    // width: 100%;
    justify-content: space-between;
    align-items: center;
    // box-shadow: 0 2px 4px 0 rgb(222 231 241 / 50%);
    border-bottom: 1px solid #e9ecf1;
    padding: 0 20px;
    margin: 0;
    box-sizing: border-box;
    z-index: 2;
    .logo {
        width: 100px;
        padding: 10px;
        margin-left: 20px;
    }
    .app_header_profile {
        display: inline-flex;
        // margin-right: 68px;
        align-items: center;
        cursor: pointer;
        z-index: 2;
        .user_image {
            height: 30px;
            width: 30px;
            border-radius: 50%;
        }
        .user_name {
            height: 22px;
            // width: 75px;
            color: $primaryDarkBlue;
            font-size: 18px;
            letter-spacing: 0;
            line-height: 22px;
            display: block;
            font-weight: 500;
            margin: 8px;
            display: -webkit-box;
            overflow: hidden;
            word-break: break-word;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            max-width: 300px;
        }
        .user_name_hello {
            height: 22px;
            // width: 75px;
            color: #989ca8;
            letter-spacing: 0;
            line-height: 22px;
            display: block;
            font-size: 16px;
            font-weight: 400;
        }
        .user_button {
            height: 19px;
            width: 19px;
        }
    }
}
.textArea-global-search {
    color: #303E4E;
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}
.user_name_char {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: #d8d8d8;
    display: flex;
    justify-content: center;
    align-items: center;
}
.line {
    border-top: 1px solid #e9ecf1;
    margin: 8px 32px;
}
.user-menu-icon {
    margin: 3px 10px 4px 5px;
}
.menu__items {
    color: '#516983';
}

.logo-wrap {
    width: 100%;
    text-align: center;
    margin: 36px 0;
}

// @media only screen and (max-width: 1024px) {
//     .app_header {
//         padding-left: 40px;
//         .logo-wrap {
//             width: 240px;
//             justify-content: center;
//             display: flex;
//             .logo {
//                 width: 95px;
//                 margin-left: 0;
//             }
//         }
//     }
// }

// @media only screen and (max-width: 768px) {
//     .app_header .logo-wrap {
//         width: 65%;
//     }
// }
