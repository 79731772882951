.css-1h51icj-MuiAutocomplete-root .MuiAutocomplete-tag {
    background-color: #e9e9eb !important;
    border-radius: 7px !important;
}
.chip-popover {
    display: flex;
    align-items: center;
    margin: 4px;
    background-color: #e9e9eb;
    border-radius: 7px !important;
    justify-content: space-between;
    padding: 4px;
}
