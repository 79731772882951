.encounterQueue-mobile {
    position: fixed;
    bottom: 15px;
    right: 120px;
    display: flex;
    z-index: 2;
    .contact {
        z-index: 2;
        .icon-encounterQueue {
            cursor: pointer;
            width: 50px;
            height: 50px;

            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0 0 8px 0px #0000002e;

            svg {
                color: #fff;
            }
        }

        &:nth-child(1) {
            margin-right: 15px;

            .icon-encounterQueue {
                background: #5571c6;
            }
        }

        &:nth-child(2) {
            .icon-encounterQueue {
                background: #4c8080;
            }
        }
    }
}

.modal-custom {
    display: flex;
    align-items: center;
    justify-content: center;
}

.wrap-ct-modal {
    height: 100%;
    background: #fff;
    width: 450px;
    max-height: 450px;
    overflow-y: auto;

    .title-modal-lb {
        font-size: 17px;
        font-weight: bold;
        padding: 15px 15px;
    }
}

.list_holder {
    width: 85%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-right: 1px solid #cfcfcf;
    padding: 0 10px;
}

.icon_holder {
    width: 15%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .each_icon_holder {
        min-width: 26px;
        max-width: 26px;
        min-height: 26px;
        max-height: 26px;
        border-radius: 100%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin: 2px;
        cursor: pointer;

        img {
            width: 14px;
        }

        &.play {
            button {
                background: #15a452;
            }
        }

        &.join {
            button {
                background: #57a5d0;
            }
        }

        &.leave {
            button {
                background: #5571c6;
            }
        }

        &.end {
            button {
                background: #ae2020;
            }
        }

        .Mui-disabled {
            background: #d5d6d9 !important;
        }
    }
}

.pl-select-option {
    &.icon_list {
        .status_holder {
            font-size: 8px;
            width: 16px;
            height: 16px;
            border-radius: 100%;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            margin-right: 4px;

            &.arrived {
                background: #15a452;
            }

            &.session {
                background: #5571c6;
            }

            &.no_show {
                background: #ae2020;
            }
            &.no_start {
                color: black;
                font-size: 12px;
                margin-left: 15px;
                ::after {
                    content: '\A';
                    white-space: pre;
                }
            }
            &.complete {
                background: #2196f3;
            }
        }
    }
}

.status_dot {
    font-size: 8px;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    position: absolute;
    right: 19px;
    bottom: -3px;

    &.arrived {
        background: #15a452;
    }

    &.session {
        background: #5571c6;
    }

    &.no_show {
        background: #ae2020;
    }

    &.no_start {
        color: black;
        font-size: 12px;
        margin-left: 15px;
        ::after {
            content: '\A';
            white-space: pre;
        }
    }

    &.complete {
        background: #2196f3;
    }
}

.inline_line_holder {
    display: flex;
    // flex-wrap: wrap;
    align-items: center;
}

.select_without_pd {
    min-height: auto !important;

    .MuiSelect-select {
        padding: 0 20px 0 0 !important;
        font-size: 12px;
    }

    .MuiSelect-icon {
        top: calc(50% - 10px);
        right: 0;
    }
}

.onboard_status {
    .each_holder {
        display: inline-flex;
        align-items: center;
        margin: 0;
        border-left: 1px solid #cfcfcf;
        padding-left: 5px;

        .name {
            padding-left: 5px;
            font-size: 12px;
        }
    }
}
